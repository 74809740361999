@import url("https://fonts.googleapis.com/css?family=Meie+Script");

.App {
  height: 2000px;
  position: relative;
  font-size: calc(10px + 2vmin);
  text-align: center;
  color: white;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

#body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #282c34;
  background: radial-gradient(#000, #282c34);
}

.script {
  font-family: "Meie Script", cursive;
}

.header {
  font-size: 2em;
  position: absolute;
  width: 100%;
  top: 20%;
}

.sub-header {
  font-size: 0.7em;
}

.mouse {
  position: absolute;
  max-width: 1.2rem;
  border: 2px solid #fff;
  border-radius: 1.5rem;
  width: 100%;
  height: auto;
  left: calc(50% - 0.6rem);
  bottom: 0.5rem;
}

.scroll {
  animation-name: scroll;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.65, -0.55, 0.25, 1.5);
  animation-iteration-count: infinite;
  transform-origin: 50% 20.5px;
  will-change: transform, opacity;
  opacity: 1;
}

.scroll-link {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes scroll {
  0%,
  20% {
    transform: translateY(0) scaleY(1);
  }
  100% {
    transform: translateY(72px) scaleY(2);
    opacity: 0;
  }
}

.links {
  display: flex;
  width: 100%;
  position: relative;
  bottom: 2px;
}

.links a {
  color: #fff;
}

.email {
  margin-left: auto;
  padding-right: 8px;
}

.phone {
  position: relative;
  padding-left: 8px;
  bottom: 3px;
}

#fade {
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #e9d9c5;
  opacity: 0;
}

#fade_2 {
  position: fixed;
  bottom: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #e9d9c5;
  opacity: 0;
}

#fade_3 {
  position: fixed;
  top: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #e9d9c5;
  opacity: 0;
}

#fade_4 {
  position: fixed;
  bottom: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #e9d9c5;
  opacity: 0;
}

#fade_5 {
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #e9d9c5;
  opacity: 0;
  width: 100vw;
  height: 100vh;
}

#fade_5 .mouse {
  position: absolute;
  max-width: 1.2rem;
  border: 2px solid #000;
  border-radius: 1.5rem;
  width: 100%;
  height: auto;
  left: calc(50% - 0.6rem);
}

#fade_5 .links {
  position: absolute;
  top: 6px;
}

#fade_5 .links .email {
  position: relative;
  top: -6px;
}

#fade_5 .links a {
  color: #000;
}

.scroll_2 {
  animation-name: scroll_2;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.65, -0.55, 0.25, 1.5);
  animation-iteration-count: infinite;
  transform-origin: 50% 20.5px;
  will-change: transform, opacity;
  opacity: 1;
}

@keyframes scroll_2 {
  0%,
  20% {
    transform: translateY(72px) scaleY(1);
  }
  100% {
    transform: translateY(0) scaleY(2);
    opacity: 0;
  }
}

.bird {
  background-image: url("./bird.svg");
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;
  animation-name: fly-cycle;
  animation-timing-function: steps(10);
  animation-iteration-count: infinite;
}

.bird--one {
  animation-duration: 2s;
  animation-delay: -0.5s;
}

.bird--two {
  animation-duration: 1.9s;
  animation-delay: -0.75s;
}

.bird--three {
  animation-duration: 2.5s;
  animation-delay: -0.25s;
}

.bird--four {
  animation-duration: 2.2s;
  animation-delay: -0.5s;
}

.bird-container {
  position: absolute;
  top: 20%;
  left: -10%;
  transform: scale(0) translateX(-10vw);
  will-change: transform;
  animation-name: fly-right-one;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.bird-container--one {
  animation-duration: 15s;
  animation-delay: 0;
}

.bird-container--two {
  animation-duration: 16s;
  animation-delay: 1s;
}

.bird-container--three {
  animation-duration: 16s;
  animation-delay: 5.5s;
}

.bird-container--four {
  animation-duration: 19s;
  animation-delay: 7s;
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-right-one {
  0% {
    transform: scale(0.3) translateX(-10vw);
  }

  10% {
    transform: translateY(2vh) translateX(10vw) scale(0.4);
  }

  20% {
    transform: translateY(0vh) translateX(30vw) scale(0.5);
  }

  30% {
    transform: translateY(4vh) translateX(50vw) scale(0.6);
  }

  40% {
    transform: translateY(2vh) translateX(70vw) scale(0.6);
  }

  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.6);
  }

  60% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }

  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
}

@keyframes fly-right-two {
  0% {
    transform: translateY(-2vh) translateX(-10vw) scale(0.5);
  }

  10% {
    transform: translateY(0vh) translateX(10vw) scale(0.4);
  }

  20% {
    transform: translateY(-4vh) translateX(30vw) scale(0.6);
  }

  30% {
    transform: translateY(1vh) translateX(50vw) scale(0.45);
  }

  40% {
    transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
  }

  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.45);
  }

  51% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }

  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
}
