@import url(https://fonts.googleapis.com/css?family=Meie+Script);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}
.App {
  height: 2000px;
  position: relative;
  font-size: calc(10px + 2vmin);
  text-align: center;
  color: white;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

#body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #282c34;
  background: -webkit-radial-gradient(#000, #282c34);
  background: radial-gradient(#000, #282c34);
}

.script {
  font-family: "Meie Script", cursive;
}

.header {
  font-size: 2em;
  position: absolute;
  width: 100%;
  top: 20%;
}

.sub-header {
  font-size: 0.7em;
}

.mouse {
  position: absolute;
  max-width: 1.2rem;
  border: 2px solid #fff;
  border-radius: 1.5rem;
  width: 100%;
  height: auto;
  left: calc(50% - 0.6rem);
  bottom: 0.5rem;
}

.scroll {
  -webkit-animation-name: scroll;
          animation-name: scroll;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-timing-function: cubic-bezier(0.65, -0.55, 0.25, 1.5);
          animation-timing-function: cubic-bezier(0.65, -0.55, 0.25, 1.5);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 20.5px;
          transform-origin: 50% 20.5px;
  will-change: transform, opacity;
  opacity: 1;
}

.scroll-link {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@-webkit-keyframes scroll {
  0%,
  20% {
    -webkit-transform: translateY(0) scaleY(1);
            transform: translateY(0) scaleY(1);
  }
  100% {
    -webkit-transform: translateY(72px) scaleY(2);
            transform: translateY(72px) scaleY(2);
    opacity: 0;
  }
}

@keyframes scroll {
  0%,
  20% {
    -webkit-transform: translateY(0) scaleY(1);
            transform: translateY(0) scaleY(1);
  }
  100% {
    -webkit-transform: translateY(72px) scaleY(2);
            transform: translateY(72px) scaleY(2);
    opacity: 0;
  }
}

.links {
  display: flex;
  width: 100%;
  position: relative;
  bottom: 2px;
}

.links a {
  color: #fff;
}

.email {
  margin-left: auto;
  padding-right: 8px;
}

.phone {
  position: relative;
  padding-left: 8px;
  bottom: 3px;
}

#fade {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e9d9c5;
  opacity: 0;
}

#fade_2 {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e9d9c5;
  opacity: 0;
}

#fade_3 {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e9d9c5;
  opacity: 0;
}

#fade_4 {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e9d9c5;
  opacity: 0;
}

#fade_5 {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e9d9c5;
  opacity: 0;
  width: 100vw;
  height: 100vh;
}

#fade_5 .mouse {
  position: absolute;
  max-width: 1.2rem;
  border: 2px solid #000;
  border-radius: 1.5rem;
  width: 100%;
  height: auto;
  left: calc(50% - 0.6rem);
}

#fade_5 .links {
  position: absolute;
  top: 6px;
}

#fade_5 .links .email {
  position: relative;
  top: -6px;
}

#fade_5 .links a {
  color: #000;
}

.scroll_2 {
  -webkit-animation-name: scroll_2;
          animation-name: scroll_2;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-timing-function: cubic-bezier(0.65, -0.55, 0.25, 1.5);
          animation-timing-function: cubic-bezier(0.65, -0.55, 0.25, 1.5);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 20.5px;
          transform-origin: 50% 20.5px;
  will-change: transform, opacity;
  opacity: 1;
}

@-webkit-keyframes scroll_2 {
  0%,
  20% {
    -webkit-transform: translateY(72px) scaleY(1);
            transform: translateY(72px) scaleY(1);
  }
  100% {
    -webkit-transform: translateY(0) scaleY(2);
            transform: translateY(0) scaleY(2);
    opacity: 0;
  }
}

@keyframes scroll_2 {
  0%,
  20% {
    -webkit-transform: translateY(72px) scaleY(1);
            transform: translateY(72px) scaleY(1);
  }
  100% {
    -webkit-transform: translateY(0) scaleY(2);
            transform: translateY(0) scaleY(2);
    opacity: 0;
  }
}

.bird {
  background-image: url(../../static/media/bird.1611a330.svg);
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;
  -webkit-animation-name: fly-cycle;
          animation-name: fly-cycle;
  -webkit-animation-timing-function: steps(10);
          animation-timing-function: steps(10);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.bird--one {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.bird--two {
  -webkit-animation-duration: 1.9s;
          animation-duration: 1.9s;
  -webkit-animation-delay: -0.75s;
          animation-delay: -0.75s;
}

.bird--three {
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s;
  -webkit-animation-delay: -0.25s;
          animation-delay: -0.25s;
}

.bird--four {
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.bird-container {
  position: absolute;
  top: 20%;
  left: -10%;
  -webkit-transform: scale(0) translateX(-10vw);
          transform: scale(0) translateX(-10vw);
  will-change: transform;
  -webkit-animation-name: fly-right-one;
          animation-name: fly-right-one;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.bird-container--one {
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
  -webkit-animation-delay: 0;
          animation-delay: 0;
}

.bird-container--two {
  -webkit-animation-duration: 16s;
          animation-duration: 16s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.bird-container--three {
  -webkit-animation-duration: 16s;
          animation-duration: 16s;
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s;
}

.bird-container--four {
  -webkit-animation-duration: 19s;
          animation-duration: 19s;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}

@-webkit-keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@-webkit-keyframes fly-right-one {
  0% {
    -webkit-transform: scale(0.3) translateX(-10vw);
            transform: scale(0.3) translateX(-10vw);
  }

  10% {
    -webkit-transform: translateY(2vh) translateX(10vw) scale(0.4);
            transform: translateY(2vh) translateX(10vw) scale(0.4);
  }

  20% {
    -webkit-transform: translateY(0vh) translateX(30vw) scale(0.5);
            transform: translateY(0vh) translateX(30vw) scale(0.5);
  }

  30% {
    -webkit-transform: translateY(4vh) translateX(50vw) scale(0.6);
            transform: translateY(4vh) translateX(50vw) scale(0.6);
  }

  40% {
    -webkit-transform: translateY(2vh) translateX(70vw) scale(0.6);
            transform: translateY(2vh) translateX(70vw) scale(0.6);
  }

  50% {
    -webkit-transform: translateY(0vh) translateX(90vw) scale(0.6);
            transform: translateY(0vh) translateX(90vw) scale(0.6);
  }

  60% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.6);
            transform: translateY(0vh) translateX(110vw) scale(0.6);
  }

  100% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.6);
            transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
}

@keyframes fly-right-one {
  0% {
    -webkit-transform: scale(0.3) translateX(-10vw);
            transform: scale(0.3) translateX(-10vw);
  }

  10% {
    -webkit-transform: translateY(2vh) translateX(10vw) scale(0.4);
            transform: translateY(2vh) translateX(10vw) scale(0.4);
  }

  20% {
    -webkit-transform: translateY(0vh) translateX(30vw) scale(0.5);
            transform: translateY(0vh) translateX(30vw) scale(0.5);
  }

  30% {
    -webkit-transform: translateY(4vh) translateX(50vw) scale(0.6);
            transform: translateY(4vh) translateX(50vw) scale(0.6);
  }

  40% {
    -webkit-transform: translateY(2vh) translateX(70vw) scale(0.6);
            transform: translateY(2vh) translateX(70vw) scale(0.6);
  }

  50% {
    -webkit-transform: translateY(0vh) translateX(90vw) scale(0.6);
            transform: translateY(0vh) translateX(90vw) scale(0.6);
  }

  60% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.6);
            transform: translateY(0vh) translateX(110vw) scale(0.6);
  }

  100% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.6);
            transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
}

@-webkit-keyframes fly-right-two {
  0% {
    -webkit-transform: translateY(-2vh) translateX(-10vw) scale(0.5);
            transform: translateY(-2vh) translateX(-10vw) scale(0.5);
  }

  10% {
    -webkit-transform: translateY(0vh) translateX(10vw) scale(0.4);
            transform: translateY(0vh) translateX(10vw) scale(0.4);
  }

  20% {
    -webkit-transform: translateY(-4vh) translateX(30vw) scale(0.6);
            transform: translateY(-4vh) translateX(30vw) scale(0.6);
  }

  30% {
    -webkit-transform: translateY(1vh) translateX(50vw) scale(0.45);
            transform: translateY(1vh) translateX(50vw) scale(0.45);
  }

  40% {
    -webkit-transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
            transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
  }

  50% {
    -webkit-transform: translateY(0vh) translateX(90vw) scale(0.45);
            transform: translateY(0vh) translateX(90vw) scale(0.45);
  }

  51% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.45);
            transform: translateY(0vh) translateX(110vw) scale(0.45);
  }

  100% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.45);
            transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
}

@keyframes fly-right-two {
  0% {
    -webkit-transform: translateY(-2vh) translateX(-10vw) scale(0.5);
            transform: translateY(-2vh) translateX(-10vw) scale(0.5);
  }

  10% {
    -webkit-transform: translateY(0vh) translateX(10vw) scale(0.4);
            transform: translateY(0vh) translateX(10vw) scale(0.4);
  }

  20% {
    -webkit-transform: translateY(-4vh) translateX(30vw) scale(0.6);
            transform: translateY(-4vh) translateX(30vw) scale(0.6);
  }

  30% {
    -webkit-transform: translateY(1vh) translateX(50vw) scale(0.45);
            transform: translateY(1vh) translateX(50vw) scale(0.45);
  }

  40% {
    -webkit-transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
            transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
  }

  50% {
    -webkit-transform: translateY(0vh) translateX(90vw) scale(0.45);
            transform: translateY(0vh) translateX(90vw) scale(0.45);
  }

  51% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.45);
            transform: translateY(0vh) translateX(110vw) scale(0.45);
  }

  100% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.45);
            transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
}

.animation__wrap {
    position: relative;
    margin: auto
  }

  .animation {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    -webkit-animation: js-spin infinite 20s linear;
    animation: js-spin infinite 20s linear;
  }
  
  @-webkit-keyframes js-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes js-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  .animation:before {
    content: '';
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    left: 8px;
    background-color: #209CEE;
    background: url(../../static/media/js.51b21241.ico) no-repeat center;
    border-radius: 50%;
    z-index: 10;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation: popup 0.6s 0.3s cubic-bezier(0.24, 1.65, 0.425, 0.92) forwards;
    animation: popup 0.6s 0.3s cubic-bezier(0.24, 1.65, 0.425, 0.92) forwards
  }
  
  @-webkit-keyframes popup {
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
  }
  
  @keyframes popup {
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
  }
  
  .circle {
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    left: 8px;
    background-color: #209CEE;
    border-radius: 50%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation: popup 0.6s 0.3s cubic-bezier(0.24, 1.65, 0.425, 0.92) forwards;
    animation: popup 0.6s 0.3s cubic-bezier(0.24, 1.65, 0.425, 0.92) forwards
  }
  
  .circle:nth-child(2) {
    background-color: #ee6820
  }
  
  .circle:nth-child(4) {
    background-color: #eecf20
  }
  
  .circle:nth-child(3) {
    background-color: #b020ee
  }
  
  .circle:nth-child(2) {
    width: 61px;
    height: 61px;
    -webkit-animation-name: loader_1;
    animation-name: loader_1;
    -webkit-animation-duration: 2.9s;
    animation-duration: 2.9s;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-play-state: running;
    animation-play-state: running
  }
  
  .circle:nth-child(3) {
    width: 46px;
    height: 46px;
    -webkit-animation-name: loader_2;
    animation-name: loader_2;
    -webkit-animation-duration: 2.6s;
    animation-duration: 2.6s;
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-play-state: running;
    animation-play-state: running
  }
  
  .circle:nth-child(4) {
    width: 63px;
    height: 63px;
    -webkit-animation-name: loader_3;
    animation-name: loader_3;
    -webkit-animation-duration: 2.3s;
    animation-duration: 2.3s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-play-state: running;
    animation-play-state: running
  }
  
  .circle:nth-child(5) {
    width: 14px;
    height: 14px;
    -webkit-animation-name: loader_4;
    animation-name: loader_4;
    -webkit-animation-duration: 2.8s;
    animation-duration: 2.8s;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-play-state: running;
    animation-play-state: running
  }
  
  .circle:nth-child(6) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: loader_5;
    animation-name: loader_5;
    -webkit-animation-duration: 2.6s;
    animation-duration: 2.6s;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-play-state: running;
    animation-play-state: running
  }
  
  .circle:nth-child(7) {
    width: 69px;
    height: 69px;
    -webkit-animation-name: loader_6;
    animation-name: loader_6;
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-play-state: running;
    animation-play-state: running
  }
  
  .circle:nth-child(8) {
    width: 27px;
    height: 27px;
    -webkit-animation-name: loader_7;
    animation-name: loader_7;
    -webkit-animation-duration: 2.6s;
    animation-duration: 2.6s;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-play-state: running;
    animation-play-state: running
  }
  
  .circle:nth-child(9) {
    width: 10px;
    height: 10px;
    -webkit-animation-name: loader_8;
    animation-name: loader_8;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-delay: 3.5s;
    animation-delay: 3.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-play-state: running;
    animation-play-state: running
  }
  
  @-webkit-keyframes loader_1 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-4px) scale(.01);
        transform: translateX(3.99334px) translateY(-4px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-8px) scale(.02);
        transform: translateX(7.94677px) translateY(-8px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-12px) scale(.03);
        transform: translateX(11.82081px) translateY(-12px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-16px) scale(.04);
        transform: translateX(15.57673px) translateY(-16px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-20px) scale(.05);
        transform: translateX(19.17702px) translateY(-20px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-24px) scale(.06);
        transform: translateX(22.5857px) translateY(-24px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-28px) scale(.07);
        transform: translateX(25.76871px) translateY(-28px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-32px) scale(.08);
        transform: translateX(28.69424px) translateY(-32px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-36px) scale(.09);
        transform: translateX(31.33308px) translateY(-36px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-40px) scale(.1);
        transform: translateX(33.65884px) translateY(-40px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-44px) scale(.11);
        transform: translateX(35.64829px) translateY(-44px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-48px) scale(.12);
        transform: translateX(37.28156px) translateY(-48px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-52px) scale(.13);
        transform: translateX(38.54233px) translateY(-52px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-56px) scale(.14);
        transform: translateX(39.41799px) translateY(-56px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-60px) scale(.15);
        transform: translateX(39.8998px) translateY(-60px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-64px) scale(.16);
        transform: translateX(39.98294px) translateY(-64px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-68px) scale(.17);
        transform: translateX(39.66659px) translateY(-68px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-72px) scale(.18);
        transform: translateX(38.95391px) translateY(-72px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-76px) scale(.19);
        transform: translateX(37.852px) translateY(-76px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-80px) scale(.2);
        transform: translateX(36.3719px) translateY(-80px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-84px) scale(.21);
        transform: translateX(34.52837px) translateY(-84px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-88px) scale(.22);
        transform: translateX(32.33986px) translateY(-88px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-92px) scale(.23);
        transform: translateX(29.82821px) translateY(-92px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-96px) scale(.24);
        transform: translateX(27.01853px) translateY(-96px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-100px) scale(.25);
        transform: translateX(23.93889px) translateY(-100px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-104px) scale(.26);
        transform: translateX(20.62005px) translateY(-104px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-108px) scale(.27);
        transform: translateX(17.0952px) translateY(-108px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-112px) scale(.28);
        transform: translateX(13.39953px) translateY(-112px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-116px) scale(.29);
        transform: translateX(9.56997px) translateY(-116px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-120px) scale(.3);
        transform: translateX(5.6448px) translateY(-120px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-124px) scale(.31);
        transform: translateX(1.66323px) translateY(-124px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-128px) scale(.32);
        transform: translateX(-2.33497px) translateY(-128px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-132px) scale(.33);
        transform: translateX(-6.30983px) translateY(-132px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-136px) scale(.34);
        transform: translateX(-10.22164px) translateY(-136px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-140px) scale(.35);
        transform: translateX(-14.03133px) translateY(-140px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-144px) scale(.36);
        transform: translateX(-17.70082px) translateY(-144px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-148px) scale(.37);
        transform: translateX(-21.19345px) translateY(-148px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-152px) scale(.38);
        transform: translateX(-24.47432px) translateY(-152px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-156px) scale(.39);
        transform: translateX(-27.51065px) translateY(-156px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-160px) scale(.4);
        transform: translateX(-30.2721px) translateY(-160px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-164px) scale(.41);
        transform: translateX(-32.73108px) translateY(-164px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-168px) scale(.42);
        transform: translateX(-34.86303px) translateY(-168px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-172px) scale(.43);
        transform: translateX(-36.64664px) translateY(-172px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-176px) scale(.44);
        transform: translateX(-38.06408px) translateY(-176px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-180px) scale(.45);
        transform: translateX(-39.1012px) translateY(-180px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-184px) scale(.46);
        transform: translateX(-39.74764px) translateY(-184px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-188px) scale(.47);
        transform: translateX(-39.99693px) translateY(-188px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-192px) scale(.48);
        transform: translateX(-39.84658px) translateY(-192px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-196px) scale(.49);
        transform: translateX(-39.29809px) translateY(-196px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-200px) scale(.5);
        transform: translateX(-38.35695px) translateY(-200px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-204px) scale(.49);
        transform: translateX(-37.03256px) translateY(-204px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-208px) scale(.48);
        transform: translateX(-35.33814px) translateY(-208px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-212px) scale(.47);
        transform: translateX(-33.29063px) translateY(-212px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-216px) scale(.46);
        transform: translateX(-30.91048px) translateY(-216px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-220px) scale(.45);
        transform: translateX(-28.22146px) translateY(-220px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-224px) scale(.44);
        transform: translateX(-25.25043px) translateY(-224px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-228px) scale(.43);
        transform: translateX(-22.02707px) translateY(-228px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-232px) scale(.42);
        transform: translateX(-18.58356px) translateY(-232px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-236px) scale(.41);
        transform: translateX(-14.95428px) translateY(-236px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-240px) scale(.4);
        transform: translateX(-11.17547px) translateY(-240px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-244px) scale(.39);
        transform: translateX(-7.28482px) translateY(-244px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-248px) scale(.38);
        transform: translateX(-3.32114px) translateY(-248px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-252px) scale(.37);
        transform: translateX(.67607px) translateY(-252px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-256px) scale(.36);
        transform: translateX(4.66701px) translateY(-256px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-260px) scale(.35);
        transform: translateX(8.61199px) translateY(-260px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-264px) scale(.34);
        transform: translateX(12.47185px) translateY(-264px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-268px) scale(.33);
        transform: translateX(16.20837px) translateY(-268px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-272px) scale(.32);
        transform: translateX(19.7847px) translateY(-272px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-276px) scale(.31);
        transform: translateX(23.16575px) translateY(-276px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-280px) scale(.3);
        transform: translateX(26.31858px) translateY(-280px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-284px) scale(.29);
        transform: translateX(29.21285px) translateY(-284px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-288px) scale(.28);
        transform: translateX(31.82116px) translateY(-288px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-292px) scale(.27);
        transform: translateX(34.11946px) translateY(-292px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-296px) scale(.26);
        transform: translateX(36.08748px) translateY(-296px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-300px) scale(.25);
        transform: translateX(37.70905px) translateY(-300px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-304px) scale(.24);
        transform: translateX(38.97256px) translateY(-304px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-308px) scale(.23);
        transform: translateX(39.87139px) translateY(-308px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-312px) scale(.22);
        transform: translateX(40.40437px) translateY(-312px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-316px) scale(.21);
        transform: translateX(40.57628px) translateY(-316px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-320px) scale(.2);
        transform: translateX(40.39848px) translateY(-320px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-324px) scale(.19);
        transform: translateX(39.88958px) translateY(-324px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-328px) scale(.18);
        transform: translateX(39.07627px) translateY(-328px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-332px) scale(.17);
        transform: translateX(37.99434px) translateY(-332px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-336px) scale(.16);
        transform: translateX(36.68989px) translateY(-336px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-340px) scale(.15);
        transform: translateX(35.22086px) translateY(-340px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-344px) scale(.14);
        transform: translateX(33.65889px) translateY(-344px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-348px) scale(.13);
        transform: translateX(32.09164px) translateY(-348px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-352px) scale(.12);
        transform: translateX(30.62571px) translateY(-352px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-356px) scale(.11);
        transform: translateX(29.39012px) translateY(-356px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-360px) scale(.1);
        transform: translateX(28.54081px) translateY(-360px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-364px) scale(.09);
        transform: translateX(28.26597px) translateY(-364px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-368px) scale(.08);
        transform: translateX(28.79279px) translateY(-368px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-372px) scale(.07);
        transform: translateX(30.39554px) translateY(-372px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-376px) scale(.06);
        transform: translateX(33.4056px) translateY(-376px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-380px) scale(.05);
        transform: translateX(38.22359px) translateY(-380px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-384px) scale(.04);
        transform: translateX(45.3341px) translateY(-384px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-388px) scale(.03);
        transform: translateX(55.3236px) translateY(-388px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-392px) scale(.02);
        transform: translateX(68.90201px) translateY(-392px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-396px) scale(.01);
        transform: translateX(86.92866px) translateY(-396px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-400px) scale(0);
        transform: translateX(110.44364px) translateY(-400px) scale(0)
    }
  }
  
  @keyframes loader_1 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-4px) scale(.01);
        transform: translateX(3.99334px) translateY(-4px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-8px) scale(.02);
        transform: translateX(7.94677px) translateY(-8px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-12px) scale(.03);
        transform: translateX(11.82081px) translateY(-12px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-16px) scale(.04);
        transform: translateX(15.57673px) translateY(-16px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-20px) scale(.05);
        transform: translateX(19.17702px) translateY(-20px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-24px) scale(.06);
        transform: translateX(22.5857px) translateY(-24px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-28px) scale(.07);
        transform: translateX(25.76871px) translateY(-28px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-32px) scale(.08);
        transform: translateX(28.69424px) translateY(-32px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-36px) scale(.09);
        transform: translateX(31.33308px) translateY(-36px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-40px) scale(.1);
        transform: translateX(33.65884px) translateY(-40px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-44px) scale(.11);
        transform: translateX(35.64829px) translateY(-44px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-48px) scale(.12);
        transform: translateX(37.28156px) translateY(-48px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-52px) scale(.13);
        transform: translateX(38.54233px) translateY(-52px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-56px) scale(.14);
        transform: translateX(39.41799px) translateY(-56px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-60px) scale(.15);
        transform: translateX(39.8998px) translateY(-60px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-64px) scale(.16);
        transform: translateX(39.98294px) translateY(-64px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-68px) scale(.17);
        transform: translateX(39.66659px) translateY(-68px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-72px) scale(.18);
        transform: translateX(38.95391px) translateY(-72px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-76px) scale(.19);
        transform: translateX(37.852px) translateY(-76px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-80px) scale(.2);
        transform: translateX(36.3719px) translateY(-80px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-84px) scale(.21);
        transform: translateX(34.52837px) translateY(-84px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-88px) scale(.22);
        transform: translateX(32.33986px) translateY(-88px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-92px) scale(.23);
        transform: translateX(29.82821px) translateY(-92px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-96px) scale(.24);
        transform: translateX(27.01853px) translateY(-96px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-100px) scale(.25);
        transform: translateX(23.93889px) translateY(-100px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-104px) scale(.26);
        transform: translateX(20.62005px) translateY(-104px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-108px) scale(.27);
        transform: translateX(17.0952px) translateY(-108px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-112px) scale(.28);
        transform: translateX(13.39953px) translateY(-112px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-116px) scale(.29);
        transform: translateX(9.56997px) translateY(-116px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-120px) scale(.3);
        transform: translateX(5.6448px) translateY(-120px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-124px) scale(.31);
        transform: translateX(1.66323px) translateY(-124px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-128px) scale(.32);
        transform: translateX(-2.33497px) translateY(-128px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-132px) scale(.33);
        transform: translateX(-6.30983px) translateY(-132px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-136px) scale(.34);
        transform: translateX(-10.22164px) translateY(-136px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-140px) scale(.35);
        transform: translateX(-14.03133px) translateY(-140px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-144px) scale(.36);
        transform: translateX(-17.70082px) translateY(-144px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-148px) scale(.37);
        transform: translateX(-21.19345px) translateY(-148px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-152px) scale(.38);
        transform: translateX(-24.47432px) translateY(-152px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-156px) scale(.39);
        transform: translateX(-27.51065px) translateY(-156px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-160px) scale(.4);
        transform: translateX(-30.2721px) translateY(-160px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-164px) scale(.41);
        transform: translateX(-32.73108px) translateY(-164px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-168px) scale(.42);
        transform: translateX(-34.86303px) translateY(-168px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-172px) scale(.43);
        transform: translateX(-36.64664px) translateY(-172px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-176px) scale(.44);
        transform: translateX(-38.06408px) translateY(-176px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-180px) scale(.45);
        transform: translateX(-39.1012px) translateY(-180px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-184px) scale(.46);
        transform: translateX(-39.74764px) translateY(-184px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-188px) scale(.47);
        transform: translateX(-39.99693px) translateY(-188px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-192px) scale(.48);
        transform: translateX(-39.84658px) translateY(-192px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-196px) scale(.49);
        transform: translateX(-39.29809px) translateY(-196px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-200px) scale(.5);
        transform: translateX(-38.35695px) translateY(-200px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-204px) scale(.49);
        transform: translateX(-37.03256px) translateY(-204px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-208px) scale(.48);
        transform: translateX(-35.33814px) translateY(-208px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-212px) scale(.47);
        transform: translateX(-33.29063px) translateY(-212px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-216px) scale(.46);
        transform: translateX(-30.91048px) translateY(-216px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-220px) scale(.45);
        transform: translateX(-28.22146px) translateY(-220px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-224px) scale(.44);
        transform: translateX(-25.25043px) translateY(-224px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-228px) scale(.43);
        transform: translateX(-22.02707px) translateY(-228px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-232px) scale(.42);
        transform: translateX(-18.58356px) translateY(-232px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-236px) scale(.41);
        transform: translateX(-14.95428px) translateY(-236px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-240px) scale(.4);
        transform: translateX(-11.17547px) translateY(-240px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-244px) scale(.39);
        transform: translateX(-7.28482px) translateY(-244px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-248px) scale(.38);
        transform: translateX(-3.32114px) translateY(-248px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-252px) scale(.37);
        transform: translateX(.67607px) translateY(-252px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-256px) scale(.36);
        transform: translateX(4.66701px) translateY(-256px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-260px) scale(.35);
        transform: translateX(8.61199px) translateY(-260px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-264px) scale(.34);
        transform: translateX(12.47185px) translateY(-264px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-268px) scale(.33);
        transform: translateX(16.20837px) translateY(-268px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-272px) scale(.32);
        transform: translateX(19.7847px) translateY(-272px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-276px) scale(.31);
        transform: translateX(23.16575px) translateY(-276px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-280px) scale(.3);
        transform: translateX(26.31858px) translateY(-280px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-284px) scale(.29);
        transform: translateX(29.21285px) translateY(-284px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-288px) scale(.28);
        transform: translateX(31.82116px) translateY(-288px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-292px) scale(.27);
        transform: translateX(34.11946px) translateY(-292px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-296px) scale(.26);
        transform: translateX(36.08748px) translateY(-296px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-300px) scale(.25);
        transform: translateX(37.70905px) translateY(-300px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-304px) scale(.24);
        transform: translateX(38.97256px) translateY(-304px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-308px) scale(.23);
        transform: translateX(39.87139px) translateY(-308px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-312px) scale(.22);
        transform: translateX(40.40437px) translateY(-312px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-316px) scale(.21);
        transform: translateX(40.57628px) translateY(-316px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-320px) scale(.2);
        transform: translateX(40.39848px) translateY(-320px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-324px) scale(.19);
        transform: translateX(39.88958px) translateY(-324px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-328px) scale(.18);
        transform: translateX(39.07627px) translateY(-328px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-332px) scale(.17);
        transform: translateX(37.99434px) translateY(-332px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-336px) scale(.16);
        transform: translateX(36.68989px) translateY(-336px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-340px) scale(.15);
        transform: translateX(35.22086px) translateY(-340px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-344px) scale(.14);
        transform: translateX(33.65889px) translateY(-344px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-348px) scale(.13);
        transform: translateX(32.09164px) translateY(-348px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-352px) scale(.12);
        transform: translateX(30.62571px) translateY(-352px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-356px) scale(.11);
        transform: translateX(29.39012px) translateY(-356px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-360px) scale(.1);
        transform: translateX(28.54081px) translateY(-360px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-364px) scale(.09);
        transform: translateX(28.26597px) translateY(-364px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-368px) scale(.08);
        transform: translateX(28.79279px) translateY(-368px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-372px) scale(.07);
        transform: translateX(30.39554px) translateY(-372px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-376px) scale(.06);
        transform: translateX(33.4056px) translateY(-376px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-380px) scale(.05);
        transform: translateX(38.22359px) translateY(-380px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-384px) scale(.04);
        transform: translateX(45.3341px) translateY(-384px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-388px) scale(.03);
        transform: translateX(55.3236px) translateY(-388px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-392px) scale(.02);
        transform: translateX(68.90201px) translateY(-392px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-396px) scale(.01);
        transform: translateX(86.92866px) translateY(-396px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-400px) scale(0);
        transform: translateX(110.44364px) translateY(-400px) scale(0)
    }
  }
  
  @-webkit-keyframes loader_2 {
      0% {
          -webkit-transform: translateX(0px) translateY(0px) scale(0);
          transform: translateX(0px) translateY(0px) scale(0)
      }
  
      1% {
          -webkit-transform: translateX(3.99334px) translateY(-6px) scale(.01);
          transform: translateX(3.99334px) translateY(-6px) scale(.01)
      }
  
      2% {
          -webkit-transform: translateX(7.94677px) translateY(-12px) scale(.02);
          transform: translateX(7.94677px) translateY(-12px) scale(.02)
      }
  
      3% {
          -webkit-transform: translateX(11.82081px) translateY(-18px) scale(.03);
          transform: translateX(11.82081px) translateY(-18px) scale(.03)
      }
  
      4% {
          -webkit-transform: translateX(15.57673px) translateY(-24px) scale(.04);
          transform: translateX(15.57673px) translateY(-24px) scale(.04)
      }
  
      5% {
          -webkit-transform: translateX(19.17702px) translateY(-30px) scale(.05);
          transform: translateX(19.17702px) translateY(-30px) scale(.05)
      }
  
      6% {
          -webkit-transform: translateX(22.5857px) translateY(-36px) scale(.06);
          transform: translateX(22.5857px) translateY(-36px) scale(.06)
      }
  
      7% {
          -webkit-transform: translateX(25.76871px) translateY(-42px) scale(.07);
          transform: translateX(25.76871px) translateY(-42px) scale(.07)
      }
  
      8% {
          -webkit-transform: translateX(28.69424px) translateY(-48px) scale(.08);
          transform: translateX(28.69424px) translateY(-48px) scale(.08)
      }
  
      9% {
          -webkit-transform: translateX(31.33308px) translateY(-54px) scale(.09);
          transform: translateX(31.33308px) translateY(-54px) scale(.09)
      }
  
      10% {
          -webkit-transform: translateX(33.65884px) translateY(-60px) scale(.1);
          transform: translateX(33.65884px) translateY(-60px) scale(.1)
      }
  
      11% {
          -webkit-transform: translateX(35.64829px) translateY(-66px) scale(.11);
          transform: translateX(35.64829px) translateY(-66px) scale(.11)
      }
  
      12% {
          -webkit-transform: translateX(37.28156px) translateY(-72px) scale(.12);
          transform: translateX(37.28156px) translateY(-72px) scale(.12)
      }
  
      13% {
          -webkit-transform: translateX(38.54233px) translateY(-78px) scale(.13);
          transform: translateX(38.54233px) translateY(-78px) scale(.13)
      }
  
      14% {
          -webkit-transform: translateX(39.41799px) translateY(-84px) scale(.14);
          transform: translateX(39.41799px) translateY(-84px) scale(.14)
      }
  
      15% {
          -webkit-transform: translateX(39.8998px) translateY(-90px) scale(.15);
          transform: translateX(39.8998px) translateY(-90px) scale(.15)
      }
  
      16% {
          -webkit-transform: translateX(39.98294px) translateY(-96px) scale(.16);
          transform: translateX(39.98294px) translateY(-96px) scale(.16)
      }
  
      17% {
          -webkit-transform: translateX(39.66659px) translateY(-102px) scale(.17);
          transform: translateX(39.66659px) translateY(-102px) scale(.17)
      }
  
      18% {
          -webkit-transform: translateX(38.95391px) translateY(-108px) scale(.18);
          transform: translateX(38.95391px) translateY(-108px) scale(.18)
      }
  
      19% {
          -webkit-transform: translateX(37.852px) translateY(-114px) scale(.19);
          transform: translateX(37.852px) translateY(-114px) scale(.19)
      }
  
      20% {
          -webkit-transform: translateX(36.3719px) translateY(-120px) scale(.2);
          transform: translateX(36.3719px) translateY(-120px) scale(.2)
      }
  
      21% {
          -webkit-transform: translateX(34.52837px) translateY(-126px) scale(.21);
          transform: translateX(34.52837px) translateY(-126px) scale(.21)
      }
  
      22% {
          -webkit-transform: translateX(32.33986px) translateY(-132px) scale(.22);
          transform: translateX(32.33986px) translateY(-132px) scale(.22)
      }
  
      23% {
          -webkit-transform: translateX(29.82821px) translateY(-138px) scale(.23);
          transform: translateX(29.82821px) translateY(-138px) scale(.23)
      }
  
      24% {
          -webkit-transform: translateX(27.01853px) translateY(-144px) scale(.24);
          transform: translateX(27.01853px) translateY(-144px) scale(.24)
      }
  
      25% {
          -webkit-transform: translateX(23.93889px) translateY(-150px) scale(.25);
          transform: translateX(23.93889px) translateY(-150px) scale(.25)
      }
  
      26% {
          -webkit-transform: translateX(20.62005px) translateY(-156px) scale(.26);
          transform: translateX(20.62005px) translateY(-156px) scale(.26)
      }
  
      27% {
          -webkit-transform: translateX(17.0952px) translateY(-162px) scale(.27);
          transform: translateX(17.0952px) translateY(-162px) scale(.27)
      }
  
      28% {
          -webkit-transform: translateX(13.39953px) translateY(-168px) scale(.28);
          transform: translateX(13.39953px) translateY(-168px) scale(.28)
      }
  
      29% {
          -webkit-transform: translateX(9.56997px) translateY(-174px) scale(.29);
          transform: translateX(9.56997px) translateY(-174px) scale(.29)
      }
  
      30% {
          -webkit-transform: translateX(5.6448px) translateY(-180px) scale(.3);
          transform: translateX(5.6448px) translateY(-180px) scale(.3)
      }
  
      31% {
          -webkit-transform: translateX(1.66323px) translateY(-186px) scale(.31);
          transform: translateX(1.66323px) translateY(-186px) scale(.31)
      }
  
      32% {
          -webkit-transform: translateX(-2.33497px) translateY(-192px) scale(.32);
          transform: translateX(-2.33497px) translateY(-192px) scale(.32)
      }
  
      33% {
          -webkit-transform: translateX(-6.30983px) translateY(-198px) scale(.33);
          transform: translateX(-6.30983px) translateY(-198px) scale(.33)
      }
  
      34% {
          -webkit-transform: translateX(-10.22164px) translateY(-204px) scale(.34);
          transform: translateX(-10.22164px) translateY(-204px) scale(.34)
      }
  
      35% {
          -webkit-transform: translateX(-14.03133px) translateY(-210px) scale(.35);
          transform: translateX(-14.03133px) translateY(-210px) scale(.35)
      }
  
      36% {
          -webkit-transform: translateX(-17.70082px) translateY(-216px) scale(.36);
          transform: translateX(-17.70082px) translateY(-216px) scale(.36)
      }
  
      37% {
          -webkit-transform: translateX(-21.19345px) translateY(-222px) scale(.37);
          transform: translateX(-21.19345px) translateY(-222px) scale(.37)
      }
  
      38% {
          -webkit-transform: translateX(-24.47432px) translateY(-228px) scale(.38);
          transform: translateX(-24.47432px) translateY(-228px) scale(.38)
      }
  
      39% {
          -webkit-transform: translateX(-27.51065px) translateY(-234px) scale(.39);
          transform: translateX(-27.51065px) translateY(-234px) scale(.39)
      }
  
      40% {
          -webkit-transform: translateX(-30.2721px) translateY(-240px) scale(.4);
          transform: translateX(-30.2721px) translateY(-240px) scale(.4)
      }
  
      41% {
          -webkit-transform: translateX(-32.73108px) translateY(-246px) scale(.41);
          transform: translateX(-32.73108px) translateY(-246px) scale(.41)
      }
  
      42% {
          -webkit-transform: translateX(-34.86303px) translateY(-252px) scale(.42);
          transform: translateX(-34.86303px) translateY(-252px) scale(.42)
      }
  
      43% {
          -webkit-transform: translateX(-36.64664px) translateY(-258px) scale(.43);
          transform: translateX(-36.64664px) translateY(-258px) scale(.43)
      }
  
      44% {
          -webkit-transform: translateX(-38.06408px) translateY(-264px) scale(.44);
          transform: translateX(-38.06408px) translateY(-264px) scale(.44)
      }
  
      45% {
          -webkit-transform: translateX(-39.1012px) translateY(-270px) scale(.45);
          transform: translateX(-39.1012px) translateY(-270px) scale(.45)
      }
  
      46% {
          -webkit-transform: translateX(-39.74764px) translateY(-276px) scale(.46);
          transform: translateX(-39.74764px) translateY(-276px) scale(.46)
      }
  
      47% {
          -webkit-transform: translateX(-39.99693px) translateY(-282px) scale(.47);
          transform: translateX(-39.99693px) translateY(-282px) scale(.47)
      }
  
      48% {
          -webkit-transform: translateX(-39.84658px) translateY(-288px) scale(.48);
          transform: translateX(-39.84658px) translateY(-288px) scale(.48)
      }
  
      49% {
          -webkit-transform: translateX(-39.29809px) translateY(-294px) scale(.49);
          transform: translateX(-39.29809px) translateY(-294px) scale(.49)
      }
  
      50% {
          -webkit-transform: translateX(-38.35695px) translateY(-300px) scale(.5);
          transform: translateX(-38.35695px) translateY(-300px) scale(.5)
      }
  
      51% {
          -webkit-transform: translateX(-37.03256px) translateY(-306px) scale(.49);
          transform: translateX(-37.03256px) translateY(-306px) scale(.49)
      }
  
      52% {
          -webkit-transform: translateX(-35.33814px) translateY(-312px) scale(.48);
          transform: translateX(-35.33814px) translateY(-312px) scale(.48)
      }
  
      53% {
          -webkit-transform: translateX(-33.29063px) translateY(-318px) scale(.47);
          transform: translateX(-33.29063px) translateY(-318px) scale(.47)
      }
  
      54% {
          -webkit-transform: translateX(-30.91048px) translateY(-324px) scale(.46);
          transform: translateX(-30.91048px) translateY(-324px) scale(.46)
      }
  
      55% {
          -webkit-transform: translateX(-28.22146px) translateY(-330px) scale(.45);
          transform: translateX(-28.22146px) translateY(-330px) scale(.45)
      }
  
      56% {
          -webkit-transform: translateX(-25.25043px) translateY(-336px) scale(.44);
          transform: translateX(-25.25043px) translateY(-336px) scale(.44)
      }
  
      57% {
          -webkit-transform: translateX(-22.02707px) translateY(-342px) scale(.43);
          transform: translateX(-22.02707px) translateY(-342px) scale(.43)
      }
  
      58% {
          -webkit-transform: translateX(-18.58356px) translateY(-348px) scale(.42);
          transform: translateX(-18.58356px) translateY(-348px) scale(.42)
      }
  
      59% {
          -webkit-transform: translateX(-14.95428px) translateY(-354px) scale(.41);
          transform: translateX(-14.95428px) translateY(-354px) scale(.41)
      }
  
      60% {
          -webkit-transform: translateX(-11.17547px) translateY(-360px) scale(.4);
          transform: translateX(-11.17547px) translateY(-360px) scale(.4)
      }
  
      61% {
          -webkit-transform: translateX(-7.28482px) translateY(-366px) scale(.39);
          transform: translateX(-7.28482px) translateY(-366px) scale(.39)
      }
  
      62% {
          -webkit-transform: translateX(-3.32114px) translateY(-372px) scale(.38);
          transform: translateX(-3.32114px) translateY(-372px) scale(.38)
      }
  
      63% {
          -webkit-transform: translateX(.67607px) translateY(-378px) scale(.37);
          transform: translateX(.67607px) translateY(-378px) scale(.37)
      }
  
      64% {
          -webkit-transform: translateX(4.66701px) translateY(-384px) scale(.36);
          transform: translateX(4.66701px) translateY(-384px) scale(.36)
      }
  
      65% {
          -webkit-transform: translateX(8.61199px) translateY(-390px) scale(.35);
          transform: translateX(8.61199px) translateY(-390px) scale(.35)
      }
  
      66% {
          -webkit-transform: translateX(12.47185px) translateY(-396px) scale(.34);
          transform: translateX(12.47185px) translateY(-396px) scale(.34)
      }
  
      67% {
          -webkit-transform: translateX(16.20837px) translateY(-402px) scale(.33);
          transform: translateX(16.20837px) translateY(-402px) scale(.33)
      }
  
      68% {
          -webkit-transform: translateX(19.7847px) translateY(-408px) scale(.32);
          transform: translateX(19.7847px) translateY(-408px) scale(.32)
      }
  
      69% {
          -webkit-transform: translateX(23.16575px) translateY(-414px) scale(.31);
          transform: translateX(23.16575px) translateY(-414px) scale(.31)
      }
  
      70% {
          -webkit-transform: translateX(26.31858px) translateY(-420px) scale(.3);
          transform: translateX(26.31858px) translateY(-420px) scale(.3)
      }
  
      71% {
          -webkit-transform: translateX(29.21285px) translateY(-426px) scale(.29);
          transform: translateX(29.21285px) translateY(-426px) scale(.29)
      }
  
      72% {
          -webkit-transform: translateX(31.82116px) translateY(-432px) scale(.28);
          transform: translateX(31.82116px) translateY(-432px) scale(.28)
      }
  
      73% {
          -webkit-transform: translateX(34.11946px) translateY(-438px) scale(.27);
          transform: translateX(34.11946px) translateY(-438px) scale(.27)
      }
  
      74% {
          -webkit-transform: translateX(36.08748px) translateY(-444px) scale(.26);
          transform: translateX(36.08748px) translateY(-444px) scale(.26)
      }
  
      75% {
          -webkit-transform: translateX(37.70905px) translateY(-450px) scale(.25);
          transform: translateX(37.70905px) translateY(-450px) scale(.25)
      }
  
      76% {
          -webkit-transform: translateX(38.97256px) translateY(-456px) scale(.24);
          transform: translateX(38.97256px) translateY(-456px) scale(.24)
      }
  
      77% {
          -webkit-transform: translateX(39.87139px) translateY(-462px) scale(.23);
          transform: translateX(39.87139px) translateY(-462px) scale(.23)
      }
  
      78% {
          -webkit-transform: translateX(40.40437px) translateY(-468px) scale(.22);
          transform: translateX(40.40437px) translateY(-468px) scale(.22)
      }
  
      79% {
          -webkit-transform: translateX(40.57628px) translateY(-474px) scale(.21);
          transform: translateX(40.57628px) translateY(-474px) scale(.21)
      }
  
      80% {
          -webkit-transform: translateX(40.39848px) translateY(-480px) scale(.2);
          transform: translateX(40.39848px) translateY(-480px) scale(.2)
      }
  
      81% {
          -webkit-transform: translateX(39.88958px) translateY(-486px) scale(.19);
          transform: translateX(39.88958px) translateY(-486px) scale(.19)
      }
  
      82% {
          -webkit-transform: translateX(39.07627px) translateY(-492px) scale(.18);
          transform: translateX(39.07627px) translateY(-492px) scale(.18)
      }
  
      83% {
          -webkit-transform: translateX(37.99434px) translateY(-498px) scale(.17);
          transform: translateX(37.99434px) translateY(-498px) scale(.17)
      }
  
      84% {
          -webkit-transform: translateX(36.68989px) translateY(-504px) scale(.16);
          transform: translateX(36.68989px) translateY(-504px) scale(.16)
      }
  
      85% {
          -webkit-transform: translateX(35.22086px) translateY(-510px) scale(.15);
          transform: translateX(35.22086px) translateY(-510px) scale(.15)
      }
  
      86% {
          -webkit-transform: translateX(33.65889px) translateY(-516px) scale(.14);
          transform: translateX(33.65889px) translateY(-516px) scale(.14)
      }
  
      87% {
          -webkit-transform: translateX(32.09164px) translateY(-522px) scale(.13);
          transform: translateX(32.09164px) translateY(-522px) scale(.13)
      }
  
      88% {
          -webkit-transform: translateX(30.62571px) translateY(-528px) scale(.12);
          transform: translateX(30.62571px) translateY(-528px) scale(.12)
      }
  
      89% {
          -webkit-transform: translateX(29.39012px) translateY(-534px) scale(.11);
          transform: translateX(29.39012px) translateY(-534px) scale(.11)
      }
  
      90% {
          -webkit-transform: translateX(28.54081px) translateY(-540px) scale(.1);
          transform: translateX(28.54081px) translateY(-540px) scale(.1)
      }
  
      91% {
          -webkit-transform: translateX(28.26597px) translateY(-546px) scale(.09);
          transform: translateX(28.26597px) translateY(-546px) scale(.09)
      }
  
      92% {
          -webkit-transform: translateX(28.79279px) translateY(-552px) scale(.08);
          transform: translateX(28.79279px) translateY(-552px) scale(.08)
      }
  
      93% {
          -webkit-transform: translateX(30.39554px) translateY(-558px) scale(.07);
          transform: translateX(30.39554px) translateY(-558px) scale(.07)
      }
  
      94% {
          -webkit-transform: translateX(33.4056px) translateY(-564px) scale(.06);
          transform: translateX(33.4056px) translateY(-564px) scale(.06)
      }
  
      95% {
          -webkit-transform: translateX(38.22359px) translateY(-570px) scale(.05);
          transform: translateX(38.22359px) translateY(-570px) scale(.05)
      }
  
      96% {
          -webkit-transform: translateX(45.3341px) translateY(-576px) scale(.04);
          transform: translateX(45.3341px) translateY(-576px) scale(.04)
      }
  
      97% {
          -webkit-transform: translateX(55.3236px) translateY(-582px) scale(.03);
          transform: translateX(55.3236px) translateY(-582px) scale(.03)
      }
  
      98% {
          -webkit-transform: translateX(68.90201px) translateY(-588px) scale(.02);
          transform: translateX(68.90201px) translateY(-588px) scale(.02)
      }
  
      99% {
          -webkit-transform: translateX(86.92866px) translateY(-594px) scale(.01);
          transform: translateX(86.92866px) translateY(-594px) scale(.01)
      }
  
      100% {
          -webkit-transform: translateX(110.44364px) translateY(-600px) scale(0);
          transform: translateX(110.44364px) translateY(-600px) scale(0)
      }
  }
  
  @keyframes loader_2 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-6px) scale(.01);
        transform: translateX(3.99334px) translateY(-6px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-12px) scale(.02);
        transform: translateX(7.94677px) translateY(-12px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-18px) scale(.03);
        transform: translateX(11.82081px) translateY(-18px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-24px) scale(.04);
        transform: translateX(15.57673px) translateY(-24px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-30px) scale(.05);
        transform: translateX(19.17702px) translateY(-30px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-36px) scale(.06);
        transform: translateX(22.5857px) translateY(-36px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-42px) scale(.07);
        transform: translateX(25.76871px) translateY(-42px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-48px) scale(.08);
        transform: translateX(28.69424px) translateY(-48px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-54px) scale(.09);
        transform: translateX(31.33308px) translateY(-54px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-60px) scale(.1);
        transform: translateX(33.65884px) translateY(-60px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-66px) scale(.11);
        transform: translateX(35.64829px) translateY(-66px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-72px) scale(.12);
        transform: translateX(37.28156px) translateY(-72px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-78px) scale(.13);
        transform: translateX(38.54233px) translateY(-78px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-84px) scale(.14);
        transform: translateX(39.41799px) translateY(-84px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-90px) scale(.15);
        transform: translateX(39.8998px) translateY(-90px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-96px) scale(.16);
        transform: translateX(39.98294px) translateY(-96px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-102px) scale(.17);
        transform: translateX(39.66659px) translateY(-102px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-108px) scale(.18);
        transform: translateX(38.95391px) translateY(-108px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-114px) scale(.19);
        transform: translateX(37.852px) translateY(-114px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-120px) scale(.2);
        transform: translateX(36.3719px) translateY(-120px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-126px) scale(.21);
        transform: translateX(34.52837px) translateY(-126px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-132px) scale(.22);
        transform: translateX(32.33986px) translateY(-132px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-138px) scale(.23);
        transform: translateX(29.82821px) translateY(-138px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-144px) scale(.24);
        transform: translateX(27.01853px) translateY(-144px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-150px) scale(.25);
        transform: translateX(23.93889px) translateY(-150px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-156px) scale(.26);
        transform: translateX(20.62005px) translateY(-156px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-162px) scale(.27);
        transform: translateX(17.0952px) translateY(-162px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-168px) scale(.28);
        transform: translateX(13.39953px) translateY(-168px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-174px) scale(.29);
        transform: translateX(9.56997px) translateY(-174px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-180px) scale(.3);
        transform: translateX(5.6448px) translateY(-180px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-186px) scale(.31);
        transform: translateX(1.66323px) translateY(-186px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-192px) scale(.32);
        transform: translateX(-2.33497px) translateY(-192px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-198px) scale(.33);
        transform: translateX(-6.30983px) translateY(-198px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-204px) scale(.34);
        transform: translateX(-10.22164px) translateY(-204px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-210px) scale(.35);
        transform: translateX(-14.03133px) translateY(-210px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-216px) scale(.36);
        transform: translateX(-17.70082px) translateY(-216px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-222px) scale(.37);
        transform: translateX(-21.19345px) translateY(-222px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-228px) scale(.38);
        transform: translateX(-24.47432px) translateY(-228px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-234px) scale(.39);
        transform: translateX(-27.51065px) translateY(-234px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-240px) scale(.4);
        transform: translateX(-30.2721px) translateY(-240px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-246px) scale(.41);
        transform: translateX(-32.73108px) translateY(-246px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-252px) scale(.42);
        transform: translateX(-34.86303px) translateY(-252px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-258px) scale(.43);
        transform: translateX(-36.64664px) translateY(-258px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-264px) scale(.44);
        transform: translateX(-38.06408px) translateY(-264px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-270px) scale(.45);
        transform: translateX(-39.1012px) translateY(-270px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-276px) scale(.46);
        transform: translateX(-39.74764px) translateY(-276px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-282px) scale(.47);
        transform: translateX(-39.99693px) translateY(-282px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-288px) scale(.48);
        transform: translateX(-39.84658px) translateY(-288px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-294px) scale(.49);
        transform: translateX(-39.29809px) translateY(-294px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-300px) scale(.5);
        transform: translateX(-38.35695px) translateY(-300px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-306px) scale(.49);
        transform: translateX(-37.03256px) translateY(-306px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-312px) scale(.48);
        transform: translateX(-35.33814px) translateY(-312px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-318px) scale(.47);
        transform: translateX(-33.29063px) translateY(-318px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-324px) scale(.46);
        transform: translateX(-30.91048px) translateY(-324px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-330px) scale(.45);
        transform: translateX(-28.22146px) translateY(-330px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-336px) scale(.44);
        transform: translateX(-25.25043px) translateY(-336px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-342px) scale(.43);
        transform: translateX(-22.02707px) translateY(-342px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-348px) scale(.42);
        transform: translateX(-18.58356px) translateY(-348px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-354px) scale(.41);
        transform: translateX(-14.95428px) translateY(-354px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-360px) scale(.4);
        transform: translateX(-11.17547px) translateY(-360px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-366px) scale(.39);
        transform: translateX(-7.28482px) translateY(-366px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-372px) scale(.38);
        transform: translateX(-3.32114px) translateY(-372px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-378px) scale(.37);
        transform: translateX(.67607px) translateY(-378px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-384px) scale(.36);
        transform: translateX(4.66701px) translateY(-384px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-390px) scale(.35);
        transform: translateX(8.61199px) translateY(-390px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-396px) scale(.34);
        transform: translateX(12.47185px) translateY(-396px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-402px) scale(.33);
        transform: translateX(16.20837px) translateY(-402px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-408px) scale(.32);
        transform: translateX(19.7847px) translateY(-408px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-414px) scale(.31);
        transform: translateX(23.16575px) translateY(-414px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-420px) scale(.3);
        transform: translateX(26.31858px) translateY(-420px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-426px) scale(.29);
        transform: translateX(29.21285px) translateY(-426px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-432px) scale(.28);
        transform: translateX(31.82116px) translateY(-432px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-438px) scale(.27);
        transform: translateX(34.11946px) translateY(-438px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-444px) scale(.26);
        transform: translateX(36.08748px) translateY(-444px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-450px) scale(.25);
        transform: translateX(37.70905px) translateY(-450px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-456px) scale(.24);
        transform: translateX(38.97256px) translateY(-456px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-462px) scale(.23);
        transform: translateX(39.87139px) translateY(-462px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-468px) scale(.22);
        transform: translateX(40.40437px) translateY(-468px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-474px) scale(.21);
        transform: translateX(40.57628px) translateY(-474px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-480px) scale(.2);
        transform: translateX(40.39848px) translateY(-480px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-486px) scale(.19);
        transform: translateX(39.88958px) translateY(-486px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-492px) scale(.18);
        transform: translateX(39.07627px) translateY(-492px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-498px) scale(.17);
        transform: translateX(37.99434px) translateY(-498px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-504px) scale(.16);
        transform: translateX(36.68989px) translateY(-504px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-510px) scale(.15);
        transform: translateX(35.22086px) translateY(-510px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-516px) scale(.14);
        transform: translateX(33.65889px) translateY(-516px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-522px) scale(.13);
        transform: translateX(32.09164px) translateY(-522px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-528px) scale(.12);
        transform: translateX(30.62571px) translateY(-528px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-534px) scale(.11);
        transform: translateX(29.39012px) translateY(-534px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-540px) scale(.1);
        transform: translateX(28.54081px) translateY(-540px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-546px) scale(.09);
        transform: translateX(28.26597px) translateY(-546px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-552px) scale(.08);
        transform: translateX(28.79279px) translateY(-552px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-558px) scale(.07);
        transform: translateX(30.39554px) translateY(-558px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-564px) scale(.06);
        transform: translateX(33.4056px) translateY(-564px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-570px) scale(.05);
        transform: translateX(38.22359px) translateY(-570px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-576px) scale(.04);
        transform: translateX(45.3341px) translateY(-576px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-582px) scale(.03);
        transform: translateX(55.3236px) translateY(-582px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-588px) scale(.02);
        transform: translateX(68.90201px) translateY(-588px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-594px) scale(.01);
        transform: translateX(86.92866px) translateY(-594px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-600px) scale(0);
        transform: translateX(110.44364px) translateY(-600px) scale(0)
    }
  }
  
  @-webkit-keyframes loader_3 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-5px) scale(.01);
        transform: translateX(3.99334px) translateY(-5px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-10px) scale(.02);
        transform: translateX(7.94677px) translateY(-10px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-15px) scale(.03);
        transform: translateX(11.82081px) translateY(-15px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-20px) scale(.04);
        transform: translateX(15.57673px) translateY(-20px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-25px) scale(.05);
        transform: translateX(19.17702px) translateY(-25px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-30px) scale(.06);
        transform: translateX(22.5857px) translateY(-30px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-35px) scale(.07);
        transform: translateX(25.76871px) translateY(-35px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-40px) scale(.08);
        transform: translateX(28.69424px) translateY(-40px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-45px) scale(.09);
        transform: translateX(31.33308px) translateY(-45px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-50px) scale(.1);
        transform: translateX(33.65884px) translateY(-50px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-55px) scale(.11);
        transform: translateX(35.64829px) translateY(-55px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-60px) scale(.12);
        transform: translateX(37.28156px) translateY(-60px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-65px) scale(.13);
        transform: translateX(38.54233px) translateY(-65px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-70px) scale(.14);
        transform: translateX(39.41799px) translateY(-70px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-75px) scale(.15);
        transform: translateX(39.8998px) translateY(-75px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-80px) scale(.16);
        transform: translateX(39.98294px) translateY(-80px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-85px) scale(.17);
        transform: translateX(39.66659px) translateY(-85px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-90px) scale(.18);
        transform: translateX(38.95391px) translateY(-90px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-95px) scale(.19);
        transform: translateX(37.852px) translateY(-95px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-100px) scale(.2);
        transform: translateX(36.3719px) translateY(-100px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-105px) scale(.21);
        transform: translateX(34.52837px) translateY(-105px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-110px) scale(.22);
        transform: translateX(32.33986px) translateY(-110px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-115px) scale(.23);
        transform: translateX(29.82821px) translateY(-115px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-120px) scale(.24);
        transform: translateX(27.01853px) translateY(-120px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-125px) scale(.25);
        transform: translateX(23.93889px) translateY(-125px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-130px) scale(.26);
        transform: translateX(20.62005px) translateY(-130px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-135px) scale(.27);
        transform: translateX(17.0952px) translateY(-135px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-140px) scale(.28);
        transform: translateX(13.39953px) translateY(-140px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-145px) scale(.29);
        transform: translateX(9.56997px) translateY(-145px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-150px) scale(.3);
        transform: translateX(5.6448px) translateY(-150px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-155px) scale(.31);
        transform: translateX(1.66323px) translateY(-155px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-160px) scale(.32);
        transform: translateX(-2.33497px) translateY(-160px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-165px) scale(.33);
        transform: translateX(-6.30983px) translateY(-165px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-170px) scale(.34);
        transform: translateX(-10.22164px) translateY(-170px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-175px) scale(.35);
        transform: translateX(-14.03133px) translateY(-175px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-180px) scale(.36);
        transform: translateX(-17.70082px) translateY(-180px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-185px) scale(.37);
        transform: translateX(-21.19345px) translateY(-185px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-190px) scale(.38);
        transform: translateX(-24.47432px) translateY(-190px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-195px) scale(.39);
        transform: translateX(-27.51065px) translateY(-195px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-200px) scale(.4);
        transform: translateX(-30.2721px) translateY(-200px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-205px) scale(.41);
        transform: translateX(-32.73108px) translateY(-205px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-210px) scale(.42);
        transform: translateX(-34.86303px) translateY(-210px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-215px) scale(.43);
        transform: translateX(-36.64664px) translateY(-215px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-220px) scale(.44);
        transform: translateX(-38.06408px) translateY(-220px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-225px) scale(.45);
        transform: translateX(-39.1012px) translateY(-225px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-230px) scale(.46);
        transform: translateX(-39.74764px) translateY(-230px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-235px) scale(.47);
        transform: translateX(-39.99693px) translateY(-235px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-240px) scale(.48);
        transform: translateX(-39.84658px) translateY(-240px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-245px) scale(.49);
        transform: translateX(-39.29809px) translateY(-245px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-250px) scale(.5);
        transform: translateX(-38.35695px) translateY(-250px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-255px) scale(.49);
        transform: translateX(-37.03256px) translateY(-255px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-260px) scale(.48);
        transform: translateX(-35.33814px) translateY(-260px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-265px) scale(.47);
        transform: translateX(-33.29063px) translateY(-265px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-270px) scale(.46);
        transform: translateX(-30.91048px) translateY(-270px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-275px) scale(.45);
        transform: translateX(-28.22146px) translateY(-275px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-280px) scale(.44);
        transform: translateX(-25.25043px) translateY(-280px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-285px) scale(.43);
        transform: translateX(-22.02707px) translateY(-285px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-290px) scale(.42);
        transform: translateX(-18.58356px) translateY(-290px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-295px) scale(.41);
        transform: translateX(-14.95428px) translateY(-295px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-300px) scale(.4);
        transform: translateX(-11.17547px) translateY(-300px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-305px) scale(.39);
        transform: translateX(-7.28482px) translateY(-305px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-310px) scale(.38);
        transform: translateX(-3.32114px) translateY(-310px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-315px) scale(.37);
        transform: translateX(.67607px) translateY(-315px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-320px) scale(.36);
        transform: translateX(4.66701px) translateY(-320px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-325px) scale(.35);
        transform: translateX(8.61199px) translateY(-325px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-330px) scale(.34);
        transform: translateX(12.47185px) translateY(-330px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-335px) scale(.33);
        transform: translateX(16.20837px) translateY(-335px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-340px) scale(.32);
        transform: translateX(19.7847px) translateY(-340px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-345px) scale(.31);
        transform: translateX(23.16575px) translateY(-345px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-350px) scale(.3);
        transform: translateX(26.31858px) translateY(-350px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-355px) scale(.29);
        transform: translateX(29.21285px) translateY(-355px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-360px) scale(.28);
        transform: translateX(31.82116px) translateY(-360px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-365px) scale(.27);
        transform: translateX(34.11946px) translateY(-365px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-370px) scale(.26);
        transform: translateX(36.08748px) translateY(-370px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-375px) scale(.25);
        transform: translateX(37.70905px) translateY(-375px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-380px) scale(.24);
        transform: translateX(38.97256px) translateY(-380px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-385px) scale(.23);
        transform: translateX(39.87139px) translateY(-385px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-390px) scale(.22);
        transform: translateX(40.40437px) translateY(-390px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-395px) scale(.21);
        transform: translateX(40.57628px) translateY(-395px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-400px) scale(.2);
        transform: translateX(40.39848px) translateY(-400px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-405px) scale(.19);
        transform: translateX(39.88958px) translateY(-405px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-410px) scale(.18);
        transform: translateX(39.07627px) translateY(-410px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-415px) scale(.17);
        transform: translateX(37.99434px) translateY(-415px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-420px) scale(.16);
        transform: translateX(36.68989px) translateY(-420px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-425px) scale(.15);
        transform: translateX(35.22086px) translateY(-425px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-430px) scale(.14);
        transform: translateX(33.65889px) translateY(-430px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-435px) scale(.13);
        transform: translateX(32.09164px) translateY(-435px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-440px) scale(.12);
        transform: translateX(30.62571px) translateY(-440px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-445px) scale(.11);
        transform: translateX(29.39012px) translateY(-445px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-450px) scale(.1);
        transform: translateX(28.54081px) translateY(-450px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-455px) scale(.09);
        transform: translateX(28.26597px) translateY(-455px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-460px) scale(.08);
        transform: translateX(28.79279px) translateY(-460px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-465px) scale(.07);
        transform: translateX(30.39554px) translateY(-465px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-470px) scale(.06);
        transform: translateX(33.4056px) translateY(-470px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-475px) scale(.05);
        transform: translateX(38.22359px) translateY(-475px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-480px) scale(.04);
        transform: translateX(45.3341px) translateY(-480px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-485px) scale(.03);
        transform: translateX(55.3236px) translateY(-485px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-490px) scale(.02);
        transform: translateX(68.90201px) translateY(-490px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-495px) scale(.01);
        transform: translateX(86.92866px) translateY(-495px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-500px) scale(0);
        transform: translateX(110.44364px) translateY(-500px) scale(0)
    }
  }
  
  @keyframes loader_3 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-5px) scale(.01);
        transform: translateX(3.99334px) translateY(-5px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-10px) scale(.02);
        transform: translateX(7.94677px) translateY(-10px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-15px) scale(.03);
        transform: translateX(11.82081px) translateY(-15px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-20px) scale(.04);
        transform: translateX(15.57673px) translateY(-20px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-25px) scale(.05);
        transform: translateX(19.17702px) translateY(-25px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-30px) scale(.06);
        transform: translateX(22.5857px) translateY(-30px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-35px) scale(.07);
        transform: translateX(25.76871px) translateY(-35px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-40px) scale(.08);
        transform: translateX(28.69424px) translateY(-40px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-45px) scale(.09);
        transform: translateX(31.33308px) translateY(-45px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-50px) scale(.1);
        transform: translateX(33.65884px) translateY(-50px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-55px) scale(.11);
        transform: translateX(35.64829px) translateY(-55px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-60px) scale(.12);
        transform: translateX(37.28156px) translateY(-60px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-65px) scale(.13);
        transform: translateX(38.54233px) translateY(-65px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-70px) scale(.14);
        transform: translateX(39.41799px) translateY(-70px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-75px) scale(.15);
        transform: translateX(39.8998px) translateY(-75px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-80px) scale(.16);
        transform: translateX(39.98294px) translateY(-80px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-85px) scale(.17);
        transform: translateX(39.66659px) translateY(-85px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-90px) scale(.18);
        transform: translateX(38.95391px) translateY(-90px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-95px) scale(.19);
        transform: translateX(37.852px) translateY(-95px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-100px) scale(.2);
        transform: translateX(36.3719px) translateY(-100px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-105px) scale(.21);
        transform: translateX(34.52837px) translateY(-105px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-110px) scale(.22);
        transform: translateX(32.33986px) translateY(-110px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-115px) scale(.23);
        transform: translateX(29.82821px) translateY(-115px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-120px) scale(.24);
        transform: translateX(27.01853px) translateY(-120px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-125px) scale(.25);
        transform: translateX(23.93889px) translateY(-125px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-130px) scale(.26);
        transform: translateX(20.62005px) translateY(-130px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-135px) scale(.27);
        transform: translateX(17.0952px) translateY(-135px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-140px) scale(.28);
        transform: translateX(13.39953px) translateY(-140px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-145px) scale(.29);
        transform: translateX(9.56997px) translateY(-145px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-150px) scale(.3);
        transform: translateX(5.6448px) translateY(-150px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-155px) scale(.31);
        transform: translateX(1.66323px) translateY(-155px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-160px) scale(.32);
        transform: translateX(-2.33497px) translateY(-160px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-165px) scale(.33);
        transform: translateX(-6.30983px) translateY(-165px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-170px) scale(.34);
        transform: translateX(-10.22164px) translateY(-170px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-175px) scale(.35);
        transform: translateX(-14.03133px) translateY(-175px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-180px) scale(.36);
        transform: translateX(-17.70082px) translateY(-180px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-185px) scale(.37);
        transform: translateX(-21.19345px) translateY(-185px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-190px) scale(.38);
        transform: translateX(-24.47432px) translateY(-190px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-195px) scale(.39);
        transform: translateX(-27.51065px) translateY(-195px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-200px) scale(.4);
        transform: translateX(-30.2721px) translateY(-200px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-205px) scale(.41);
        transform: translateX(-32.73108px) translateY(-205px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-210px) scale(.42);
        transform: translateX(-34.86303px) translateY(-210px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-215px) scale(.43);
        transform: translateX(-36.64664px) translateY(-215px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-220px) scale(.44);
        transform: translateX(-38.06408px) translateY(-220px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-225px) scale(.45);
        transform: translateX(-39.1012px) translateY(-225px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-230px) scale(.46);
        transform: translateX(-39.74764px) translateY(-230px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-235px) scale(.47);
        transform: translateX(-39.99693px) translateY(-235px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-240px) scale(.48);
        transform: translateX(-39.84658px) translateY(-240px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-245px) scale(.49);
        transform: translateX(-39.29809px) translateY(-245px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-250px) scale(.5);
        transform: translateX(-38.35695px) translateY(-250px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-255px) scale(.49);
        transform: translateX(-37.03256px) translateY(-255px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-260px) scale(.48);
        transform: translateX(-35.33814px) translateY(-260px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-265px) scale(.47);
        transform: translateX(-33.29063px) translateY(-265px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-270px) scale(.46);
        transform: translateX(-30.91048px) translateY(-270px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-275px) scale(.45);
        transform: translateX(-28.22146px) translateY(-275px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-280px) scale(.44);
        transform: translateX(-25.25043px) translateY(-280px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-285px) scale(.43);
        transform: translateX(-22.02707px) translateY(-285px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-290px) scale(.42);
        transform: translateX(-18.58356px) translateY(-290px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-295px) scale(.41);
        transform: translateX(-14.95428px) translateY(-295px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-300px) scale(.4);
        transform: translateX(-11.17547px) translateY(-300px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-305px) scale(.39);
        transform: translateX(-7.28482px) translateY(-305px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-310px) scale(.38);
        transform: translateX(-3.32114px) translateY(-310px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-315px) scale(.37);
        transform: translateX(.67607px) translateY(-315px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-320px) scale(.36);
        transform: translateX(4.66701px) translateY(-320px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-325px) scale(.35);
        transform: translateX(8.61199px) translateY(-325px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-330px) scale(.34);
        transform: translateX(12.47185px) translateY(-330px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-335px) scale(.33);
        transform: translateX(16.20837px) translateY(-335px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-340px) scale(.32);
        transform: translateX(19.7847px) translateY(-340px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-345px) scale(.31);
        transform: translateX(23.16575px) translateY(-345px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-350px) scale(.3);
        transform: translateX(26.31858px) translateY(-350px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-355px) scale(.29);
        transform: translateX(29.21285px) translateY(-355px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-360px) scale(.28);
        transform: translateX(31.82116px) translateY(-360px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-365px) scale(.27);
        transform: translateX(34.11946px) translateY(-365px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-370px) scale(.26);
        transform: translateX(36.08748px) translateY(-370px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-375px) scale(.25);
        transform: translateX(37.70905px) translateY(-375px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-380px) scale(.24);
        transform: translateX(38.97256px) translateY(-380px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-385px) scale(.23);
        transform: translateX(39.87139px) translateY(-385px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-390px) scale(.22);
        transform: translateX(40.40437px) translateY(-390px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-395px) scale(.21);
        transform: translateX(40.57628px) translateY(-395px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-400px) scale(.2);
        transform: translateX(40.39848px) translateY(-400px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-405px) scale(.19);
        transform: translateX(39.88958px) translateY(-405px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-410px) scale(.18);
        transform: translateX(39.07627px) translateY(-410px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-415px) scale(.17);
        transform: translateX(37.99434px) translateY(-415px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-420px) scale(.16);
        transform: translateX(36.68989px) translateY(-420px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-425px) scale(.15);
        transform: translateX(35.22086px) translateY(-425px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-430px) scale(.14);
        transform: translateX(33.65889px) translateY(-430px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-435px) scale(.13);
        transform: translateX(32.09164px) translateY(-435px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-440px) scale(.12);
        transform: translateX(30.62571px) translateY(-440px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-445px) scale(.11);
        transform: translateX(29.39012px) translateY(-445px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-450px) scale(.1);
        transform: translateX(28.54081px) translateY(-450px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-455px) scale(.09);
        transform: translateX(28.26597px) translateY(-455px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-460px) scale(.08);
        transform: translateX(28.79279px) translateY(-460px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-465px) scale(.07);
        transform: translateX(30.39554px) translateY(-465px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-470px) scale(.06);
        transform: translateX(33.4056px) translateY(-470px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-475px) scale(.05);
        transform: translateX(38.22359px) translateY(-475px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-480px) scale(.04);
        transform: translateX(45.3341px) translateY(-480px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-485px) scale(.03);
        transform: translateX(55.3236px) translateY(-485px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-490px) scale(.02);
        transform: translateX(68.90201px) translateY(-490px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-495px) scale(.01);
        transform: translateX(86.92866px) translateY(-495px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-500px) scale(0);
        transform: translateX(110.44364px) translateY(-500px) scale(0)
    }
  }
  
  @-webkit-keyframes loader_4 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-5px) scale(.01);
        transform: translateX(3.99334px) translateY(-5px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-10px) scale(.02);
        transform: translateX(7.94677px) translateY(-10px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-15px) scale(.03);
        transform: translateX(11.82081px) translateY(-15px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-20px) scale(.04);
        transform: translateX(15.57673px) translateY(-20px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-25px) scale(.05);
        transform: translateX(19.17702px) translateY(-25px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-30px) scale(.06);
        transform: translateX(22.5857px) translateY(-30px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-35px) scale(.07);
        transform: translateX(25.76871px) translateY(-35px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-40px) scale(.08);
        transform: translateX(28.69424px) translateY(-40px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-45px) scale(.09);
        transform: translateX(31.33308px) translateY(-45px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-50px) scale(.1);
        transform: translateX(33.65884px) translateY(-50px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-55px) scale(.11);
        transform: translateX(35.64829px) translateY(-55px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-60px) scale(.12);
        transform: translateX(37.28156px) translateY(-60px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-65px) scale(.13);
        transform: translateX(38.54233px) translateY(-65px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-70px) scale(.14);
        transform: translateX(39.41799px) translateY(-70px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-75px) scale(.15);
        transform: translateX(39.8998px) translateY(-75px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-80px) scale(.16);
        transform: translateX(39.98294px) translateY(-80px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-85px) scale(.17);
        transform: translateX(39.66659px) translateY(-85px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-90px) scale(.18);
        transform: translateX(38.95391px) translateY(-90px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-95px) scale(.19);
        transform: translateX(37.852px) translateY(-95px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-100px) scale(.2);
        transform: translateX(36.3719px) translateY(-100px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-105px) scale(.21);
        transform: translateX(34.52837px) translateY(-105px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-110px) scale(.22);
        transform: translateX(32.33986px) translateY(-110px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-115px) scale(.23);
        transform: translateX(29.82821px) translateY(-115px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-120px) scale(.24);
        transform: translateX(27.01853px) translateY(-120px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-125px) scale(.25);
        transform: translateX(23.93889px) translateY(-125px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-130px) scale(.26);
        transform: translateX(20.62005px) translateY(-130px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-135px) scale(.27);
        transform: translateX(17.0952px) translateY(-135px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-140px) scale(.28);
        transform: translateX(13.39953px) translateY(-140px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-145px) scale(.29);
        transform: translateX(9.56997px) translateY(-145px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-150px) scale(.3);
        transform: translateX(5.6448px) translateY(-150px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-155px) scale(.31);
        transform: translateX(1.66323px) translateY(-155px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-160px) scale(.32);
        transform: translateX(-2.33497px) translateY(-160px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-165px) scale(.33);
        transform: translateX(-6.30983px) translateY(-165px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-170px) scale(.34);
        transform: translateX(-10.22164px) translateY(-170px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-175px) scale(.35);
        transform: translateX(-14.03133px) translateY(-175px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-180px) scale(.36);
        transform: translateX(-17.70082px) translateY(-180px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-185px) scale(.37);
        transform: translateX(-21.19345px) translateY(-185px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-190px) scale(.38);
        transform: translateX(-24.47432px) translateY(-190px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-195px) scale(.39);
        transform: translateX(-27.51065px) translateY(-195px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-200px) scale(.4);
        transform: translateX(-30.2721px) translateY(-200px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-205px) scale(.41);
        transform: translateX(-32.73108px) translateY(-205px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-210px) scale(.42);
        transform: translateX(-34.86303px) translateY(-210px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-215px) scale(.43);
        transform: translateX(-36.64664px) translateY(-215px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-220px) scale(.44);
        transform: translateX(-38.06408px) translateY(-220px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-225px) scale(.45);
        transform: translateX(-39.1012px) translateY(-225px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-230px) scale(.46);
        transform: translateX(-39.74764px) translateY(-230px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-235px) scale(.47);
        transform: translateX(-39.99693px) translateY(-235px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-240px) scale(.48);
        transform: translateX(-39.84658px) translateY(-240px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-245px) scale(.49);
        transform: translateX(-39.29809px) translateY(-245px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-250px) scale(.5);
        transform: translateX(-38.35695px) translateY(-250px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-255px) scale(.49);
        transform: translateX(-37.03256px) translateY(-255px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-260px) scale(.48);
        transform: translateX(-35.33814px) translateY(-260px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-265px) scale(.47);
        transform: translateX(-33.29063px) translateY(-265px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-270px) scale(.46);
        transform: translateX(-30.91048px) translateY(-270px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-275px) scale(.45);
        transform: translateX(-28.22146px) translateY(-275px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-280px) scale(.44);
        transform: translateX(-25.25043px) translateY(-280px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-285px) scale(.43);
        transform: translateX(-22.02707px) translateY(-285px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-290px) scale(.42);
        transform: translateX(-18.58356px) translateY(-290px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-295px) scale(.41);
        transform: translateX(-14.95428px) translateY(-295px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-300px) scale(.4);
        transform: translateX(-11.17547px) translateY(-300px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-305px) scale(.39);
        transform: translateX(-7.28482px) translateY(-305px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-310px) scale(.38);
        transform: translateX(-3.32114px) translateY(-310px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-315px) scale(.37);
        transform: translateX(.67607px) translateY(-315px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-320px) scale(.36);
        transform: translateX(4.66701px) translateY(-320px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-325px) scale(.35);
        transform: translateX(8.61199px) translateY(-325px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-330px) scale(.34);
        transform: translateX(12.47185px) translateY(-330px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-335px) scale(.33);
        transform: translateX(16.20837px) translateY(-335px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-340px) scale(.32);
        transform: translateX(19.7847px) translateY(-340px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-345px) scale(.31);
        transform: translateX(23.16575px) translateY(-345px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-350px) scale(.3);
        transform: translateX(26.31858px) translateY(-350px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-355px) scale(.29);
        transform: translateX(29.21285px) translateY(-355px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-360px) scale(.28);
        transform: translateX(31.82116px) translateY(-360px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-365px) scale(.27);
        transform: translateX(34.11946px) translateY(-365px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-370px) scale(.26);
        transform: translateX(36.08748px) translateY(-370px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-375px) scale(.25);
        transform: translateX(37.70905px) translateY(-375px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-380px) scale(.24);
        transform: translateX(38.97256px) translateY(-380px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-385px) scale(.23);
        transform: translateX(39.87139px) translateY(-385px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-390px) scale(.22);
        transform: translateX(40.40437px) translateY(-390px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-395px) scale(.21);
        transform: translateX(40.57628px) translateY(-395px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-400px) scale(.2);
        transform: translateX(40.39848px) translateY(-400px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-405px) scale(.19);
        transform: translateX(39.88958px) translateY(-405px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-410px) scale(.18);
        transform: translateX(39.07627px) translateY(-410px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-415px) scale(.17);
        transform: translateX(37.99434px) translateY(-415px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-420px) scale(.16);
        transform: translateX(36.68989px) translateY(-420px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-425px) scale(.15);
        transform: translateX(35.22086px) translateY(-425px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-430px) scale(.14);
        transform: translateX(33.65889px) translateY(-430px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-435px) scale(.13);
        transform: translateX(32.09164px) translateY(-435px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-440px) scale(.12);
        transform: translateX(30.62571px) translateY(-440px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-445px) scale(.11);
        transform: translateX(29.39012px) translateY(-445px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-450px) scale(.1);
        transform: translateX(28.54081px) translateY(-450px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-455px) scale(.09);
        transform: translateX(28.26597px) translateY(-455px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-460px) scale(.08);
        transform: translateX(28.79279px) translateY(-460px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-465px) scale(.07);
        transform: translateX(30.39554px) translateY(-465px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-470px) scale(.06);
        transform: translateX(33.4056px) translateY(-470px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-475px) scale(.05);
        transform: translateX(38.22359px) translateY(-475px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-480px) scale(.04);
        transform: translateX(45.3341px) translateY(-480px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-485px) scale(.03);
        transform: translateX(55.3236px) translateY(-485px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-490px) scale(.02);
        transform: translateX(68.90201px) translateY(-490px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-495px) scale(.01);
        transform: translateX(86.92866px) translateY(-495px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-500px) scale(0);
        transform: translateX(110.44364px) translateY(-500px) scale(0)
    }
  }
  
  @keyframes loader_4 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-5px) scale(.01);
        transform: translateX(3.99334px) translateY(-5px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-10px) scale(.02);
        transform: translateX(7.94677px) translateY(-10px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-15px) scale(.03);
        transform: translateX(11.82081px) translateY(-15px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-20px) scale(.04);
        transform: translateX(15.57673px) translateY(-20px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-25px) scale(.05);
        transform: translateX(19.17702px) translateY(-25px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-30px) scale(.06);
        transform: translateX(22.5857px) translateY(-30px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-35px) scale(.07);
        transform: translateX(25.76871px) translateY(-35px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-40px) scale(.08);
        transform: translateX(28.69424px) translateY(-40px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-45px) scale(.09);
        transform: translateX(31.33308px) translateY(-45px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-50px) scale(.1);
        transform: translateX(33.65884px) translateY(-50px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-55px) scale(.11);
        transform: translateX(35.64829px) translateY(-55px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-60px) scale(.12);
        transform: translateX(37.28156px) translateY(-60px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-65px) scale(.13);
        transform: translateX(38.54233px) translateY(-65px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-70px) scale(.14);
        transform: translateX(39.41799px) translateY(-70px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-75px) scale(.15);
        transform: translateX(39.8998px) translateY(-75px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-80px) scale(.16);
        transform: translateX(39.98294px) translateY(-80px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-85px) scale(.17);
        transform: translateX(39.66659px) translateY(-85px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-90px) scale(.18);
        transform: translateX(38.95391px) translateY(-90px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-95px) scale(.19);
        transform: translateX(37.852px) translateY(-95px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-100px) scale(.2);
        transform: translateX(36.3719px) translateY(-100px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-105px) scale(.21);
        transform: translateX(34.52837px) translateY(-105px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-110px) scale(.22);
        transform: translateX(32.33986px) translateY(-110px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-115px) scale(.23);
        transform: translateX(29.82821px) translateY(-115px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-120px) scale(.24);
        transform: translateX(27.01853px) translateY(-120px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-125px) scale(.25);
        transform: translateX(23.93889px) translateY(-125px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-130px) scale(.26);
        transform: translateX(20.62005px) translateY(-130px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-135px) scale(.27);
        transform: translateX(17.0952px) translateY(-135px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-140px) scale(.28);
        transform: translateX(13.39953px) translateY(-140px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-145px) scale(.29);
        transform: translateX(9.56997px) translateY(-145px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-150px) scale(.3);
        transform: translateX(5.6448px) translateY(-150px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-155px) scale(.31);
        transform: translateX(1.66323px) translateY(-155px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-160px) scale(.32);
        transform: translateX(-2.33497px) translateY(-160px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-165px) scale(.33);
        transform: translateX(-6.30983px) translateY(-165px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-170px) scale(.34);
        transform: translateX(-10.22164px) translateY(-170px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-175px) scale(.35);
        transform: translateX(-14.03133px) translateY(-175px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-180px) scale(.36);
        transform: translateX(-17.70082px) translateY(-180px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-185px) scale(.37);
        transform: translateX(-21.19345px) translateY(-185px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-190px) scale(.38);
        transform: translateX(-24.47432px) translateY(-190px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-195px) scale(.39);
        transform: translateX(-27.51065px) translateY(-195px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-200px) scale(.4);
        transform: translateX(-30.2721px) translateY(-200px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-205px) scale(.41);
        transform: translateX(-32.73108px) translateY(-205px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-210px) scale(.42);
        transform: translateX(-34.86303px) translateY(-210px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-215px) scale(.43);
        transform: translateX(-36.64664px) translateY(-215px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-220px) scale(.44);
        transform: translateX(-38.06408px) translateY(-220px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-225px) scale(.45);
        transform: translateX(-39.1012px) translateY(-225px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-230px) scale(.46);
        transform: translateX(-39.74764px) translateY(-230px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-235px) scale(.47);
        transform: translateX(-39.99693px) translateY(-235px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-240px) scale(.48);
        transform: translateX(-39.84658px) translateY(-240px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-245px) scale(.49);
        transform: translateX(-39.29809px) translateY(-245px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-250px) scale(.5);
        transform: translateX(-38.35695px) translateY(-250px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-255px) scale(.49);
        transform: translateX(-37.03256px) translateY(-255px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-260px) scale(.48);
        transform: translateX(-35.33814px) translateY(-260px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-265px) scale(.47);
        transform: translateX(-33.29063px) translateY(-265px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-270px) scale(.46);
        transform: translateX(-30.91048px) translateY(-270px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-275px) scale(.45);
        transform: translateX(-28.22146px) translateY(-275px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-280px) scale(.44);
        transform: translateX(-25.25043px) translateY(-280px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-285px) scale(.43);
        transform: translateX(-22.02707px) translateY(-285px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-290px) scale(.42);
        transform: translateX(-18.58356px) translateY(-290px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-295px) scale(.41);
        transform: translateX(-14.95428px) translateY(-295px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-300px) scale(.4);
        transform: translateX(-11.17547px) translateY(-300px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-305px) scale(.39);
        transform: translateX(-7.28482px) translateY(-305px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-310px) scale(.38);
        transform: translateX(-3.32114px) translateY(-310px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-315px) scale(.37);
        transform: translateX(.67607px) translateY(-315px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-320px) scale(.36);
        transform: translateX(4.66701px) translateY(-320px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-325px) scale(.35);
        transform: translateX(8.61199px) translateY(-325px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-330px) scale(.34);
        transform: translateX(12.47185px) translateY(-330px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-335px) scale(.33);
        transform: translateX(16.20837px) translateY(-335px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-340px) scale(.32);
        transform: translateX(19.7847px) translateY(-340px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-345px) scale(.31);
        transform: translateX(23.16575px) translateY(-345px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-350px) scale(.3);
        transform: translateX(26.31858px) translateY(-350px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-355px) scale(.29);
        transform: translateX(29.21285px) translateY(-355px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-360px) scale(.28);
        transform: translateX(31.82116px) translateY(-360px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-365px) scale(.27);
        transform: translateX(34.11946px) translateY(-365px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-370px) scale(.26);
        transform: translateX(36.08748px) translateY(-370px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-375px) scale(.25);
        transform: translateX(37.70905px) translateY(-375px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-380px) scale(.24);
        transform: translateX(38.97256px) translateY(-380px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-385px) scale(.23);
        transform: translateX(39.87139px) translateY(-385px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-390px) scale(.22);
        transform: translateX(40.40437px) translateY(-390px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-395px) scale(.21);
        transform: translateX(40.57628px) translateY(-395px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-400px) scale(.2);
        transform: translateX(40.39848px) translateY(-400px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-405px) scale(.19);
        transform: translateX(39.88958px) translateY(-405px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-410px) scale(.18);
        transform: translateX(39.07627px) translateY(-410px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-415px) scale(.17);
        transform: translateX(37.99434px) translateY(-415px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-420px) scale(.16);
        transform: translateX(36.68989px) translateY(-420px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-425px) scale(.15);
        transform: translateX(35.22086px) translateY(-425px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-430px) scale(.14);
        transform: translateX(33.65889px) translateY(-430px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-435px) scale(.13);
        transform: translateX(32.09164px) translateY(-435px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-440px) scale(.12);
        transform: translateX(30.62571px) translateY(-440px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-445px) scale(.11);
        transform: translateX(29.39012px) translateY(-445px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-450px) scale(.1);
        transform: translateX(28.54081px) translateY(-450px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-455px) scale(.09);
        transform: translateX(28.26597px) translateY(-455px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-460px) scale(.08);
        transform: translateX(28.79279px) translateY(-460px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-465px) scale(.07);
        transform: translateX(30.39554px) translateY(-465px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-470px) scale(.06);
        transform: translateX(33.4056px) translateY(-470px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-475px) scale(.05);
        transform: translateX(38.22359px) translateY(-475px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-480px) scale(.04);
        transform: translateX(45.3341px) translateY(-480px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-485px) scale(.03);
        transform: translateX(55.3236px) translateY(-485px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-490px) scale(.02);
        transform: translateX(68.90201px) translateY(-490px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-495px) scale(.01);
        transform: translateX(86.92866px) translateY(-495px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-500px) scale(0);
        transform: translateX(110.44364px) translateY(-500px) scale(0)
    }
  }
  
  @-webkit-keyframes loader_5 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-6px) scale(.01);
        transform: translateX(3.99334px) translateY(-6px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-12px) scale(.02);
        transform: translateX(7.94677px) translateY(-12px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-18px) scale(.03);
        transform: translateX(11.82081px) translateY(-18px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-24px) scale(.04);
        transform: translateX(15.57673px) translateY(-24px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-30px) scale(.05);
        transform: translateX(19.17702px) translateY(-30px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-36px) scale(.06);
        transform: translateX(22.5857px) translateY(-36px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-42px) scale(.07);
        transform: translateX(25.76871px) translateY(-42px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-48px) scale(.08);
        transform: translateX(28.69424px) translateY(-48px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-54px) scale(.09);
        transform: translateX(31.33308px) translateY(-54px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-60px) scale(.1);
        transform: translateX(33.65884px) translateY(-60px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-66px) scale(.11);
        transform: translateX(35.64829px) translateY(-66px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-72px) scale(.12);
        transform: translateX(37.28156px) translateY(-72px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-78px) scale(.13);
        transform: translateX(38.54233px) translateY(-78px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-84px) scale(.14);
        transform: translateX(39.41799px) translateY(-84px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-90px) scale(.15);
        transform: translateX(39.8998px) translateY(-90px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-96px) scale(.16);
        transform: translateX(39.98294px) translateY(-96px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-102px) scale(.17);
        transform: translateX(39.66659px) translateY(-102px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-108px) scale(.18);
        transform: translateX(38.95391px) translateY(-108px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-114px) scale(.19);
        transform: translateX(37.852px) translateY(-114px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-120px) scale(.2);
        transform: translateX(36.3719px) translateY(-120px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-126px) scale(.21);
        transform: translateX(34.52837px) translateY(-126px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-132px) scale(.22);
        transform: translateX(32.33986px) translateY(-132px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-138px) scale(.23);
        transform: translateX(29.82821px) translateY(-138px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-144px) scale(.24);
        transform: translateX(27.01853px) translateY(-144px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-150px) scale(.25);
        transform: translateX(23.93889px) translateY(-150px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-156px) scale(.26);
        transform: translateX(20.62005px) translateY(-156px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-162px) scale(.27);
        transform: translateX(17.0952px) translateY(-162px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-168px) scale(.28);
        transform: translateX(13.39953px) translateY(-168px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-174px) scale(.29);
        transform: translateX(9.56997px) translateY(-174px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-180px) scale(.3);
        transform: translateX(5.6448px) translateY(-180px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-186px) scale(.31);
        transform: translateX(1.66323px) translateY(-186px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-192px) scale(.32);
        transform: translateX(-2.33497px) translateY(-192px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-198px) scale(.33);
        transform: translateX(-6.30983px) translateY(-198px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-204px) scale(.34);
        transform: translateX(-10.22164px) translateY(-204px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-210px) scale(.35);
        transform: translateX(-14.03133px) translateY(-210px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-216px) scale(.36);
        transform: translateX(-17.70082px) translateY(-216px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-222px) scale(.37);
        transform: translateX(-21.19345px) translateY(-222px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-228px) scale(.38);
        transform: translateX(-24.47432px) translateY(-228px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-234px) scale(.39);
        transform: translateX(-27.51065px) translateY(-234px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-240px) scale(.4);
        transform: translateX(-30.2721px) translateY(-240px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-246px) scale(.41);
        transform: translateX(-32.73108px) translateY(-246px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-252px) scale(.42);
        transform: translateX(-34.86303px) translateY(-252px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-258px) scale(.43);
        transform: translateX(-36.64664px) translateY(-258px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-264px) scale(.44);
        transform: translateX(-38.06408px) translateY(-264px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-270px) scale(.45);
        transform: translateX(-39.1012px) translateY(-270px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-276px) scale(.46);
        transform: translateX(-39.74764px) translateY(-276px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-282px) scale(.47);
        transform: translateX(-39.99693px) translateY(-282px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-288px) scale(.48);
        transform: translateX(-39.84658px) translateY(-288px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-294px) scale(.49);
        transform: translateX(-39.29809px) translateY(-294px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-300px) scale(.5);
        transform: translateX(-38.35695px) translateY(-300px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-306px) scale(.49);
        transform: translateX(-37.03256px) translateY(-306px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-312px) scale(.48);
        transform: translateX(-35.33814px) translateY(-312px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-318px) scale(.47);
        transform: translateX(-33.29063px) translateY(-318px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-324px) scale(.46);
        transform: translateX(-30.91048px) translateY(-324px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-330px) scale(.45);
        transform: translateX(-28.22146px) translateY(-330px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-336px) scale(.44);
        transform: translateX(-25.25043px) translateY(-336px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-342px) scale(.43);
        transform: translateX(-22.02707px) translateY(-342px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-348px) scale(.42);
        transform: translateX(-18.58356px) translateY(-348px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-354px) scale(.41);
        transform: translateX(-14.95428px) translateY(-354px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-360px) scale(.4);
        transform: translateX(-11.17547px) translateY(-360px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-366px) scale(.39);
        transform: translateX(-7.28482px) translateY(-366px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-372px) scale(.38);
        transform: translateX(-3.32114px) translateY(-372px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-378px) scale(.37);
        transform: translateX(.67607px) translateY(-378px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-384px) scale(.36);
        transform: translateX(4.66701px) translateY(-384px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-390px) scale(.35);
        transform: translateX(8.61199px) translateY(-390px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-396px) scale(.34);
        transform: translateX(12.47185px) translateY(-396px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-402px) scale(.33);
        transform: translateX(16.20837px) translateY(-402px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-408px) scale(.32);
        transform: translateX(19.7847px) translateY(-408px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-414px) scale(.31);
        transform: translateX(23.16575px) translateY(-414px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-420px) scale(.3);
        transform: translateX(26.31858px) translateY(-420px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-426px) scale(.29);
        transform: translateX(29.21285px) translateY(-426px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-432px) scale(.28);
        transform: translateX(31.82116px) translateY(-432px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-438px) scale(.27);
        transform: translateX(34.11946px) translateY(-438px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-444px) scale(.26);
        transform: translateX(36.08748px) translateY(-444px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-450px) scale(.25);
        transform: translateX(37.70905px) translateY(-450px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-456px) scale(.24);
        transform: translateX(38.97256px) translateY(-456px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-462px) scale(.23);
        transform: translateX(39.87139px) translateY(-462px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-468px) scale(.22);
        transform: translateX(40.40437px) translateY(-468px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-474px) scale(.21);
        transform: translateX(40.57628px) translateY(-474px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-480px) scale(.2);
        transform: translateX(40.39848px) translateY(-480px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-486px) scale(.19);
        transform: translateX(39.88958px) translateY(-486px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-492px) scale(.18);
        transform: translateX(39.07627px) translateY(-492px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-498px) scale(.17);
        transform: translateX(37.99434px) translateY(-498px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-504px) scale(.16);
        transform: translateX(36.68989px) translateY(-504px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-510px) scale(.15);
        transform: translateX(35.22086px) translateY(-510px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-516px) scale(.14);
        transform: translateX(33.65889px) translateY(-516px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-522px) scale(.13);
        transform: translateX(32.09164px) translateY(-522px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-528px) scale(.12);
        transform: translateX(30.62571px) translateY(-528px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-534px) scale(.11);
        transform: translateX(29.39012px) translateY(-534px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-540px) scale(.1);
        transform: translateX(28.54081px) translateY(-540px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-546px) scale(.09);
        transform: translateX(28.26597px) translateY(-546px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-552px) scale(.08);
        transform: translateX(28.79279px) translateY(-552px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-558px) scale(.07);
        transform: translateX(30.39554px) translateY(-558px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-564px) scale(.06);
        transform: translateX(33.4056px) translateY(-564px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-570px) scale(.05);
        transform: translateX(38.22359px) translateY(-570px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-576px) scale(.04);
        transform: translateX(45.3341px) translateY(-576px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-582px) scale(.03);
        transform: translateX(55.3236px) translateY(-582px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-588px) scale(.02);
        transform: translateX(68.90201px) translateY(-588px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-594px) scale(.01);
        transform: translateX(86.92866px) translateY(-594px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-600px) scale(0);
        transform: translateX(110.44364px) translateY(-600px) scale(0)
    }
  }
  
  @keyframes loader_5 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-6px) scale(.01);
        transform: translateX(3.99334px) translateY(-6px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-12px) scale(.02);
        transform: translateX(7.94677px) translateY(-12px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-18px) scale(.03);
        transform: translateX(11.82081px) translateY(-18px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-24px) scale(.04);
        transform: translateX(15.57673px) translateY(-24px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-30px) scale(.05);
        transform: translateX(19.17702px) translateY(-30px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-36px) scale(.06);
        transform: translateX(22.5857px) translateY(-36px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-42px) scale(.07);
        transform: translateX(25.76871px) translateY(-42px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-48px) scale(.08);
        transform: translateX(28.69424px) translateY(-48px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-54px) scale(.09);
        transform: translateX(31.33308px) translateY(-54px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-60px) scale(.1);
        transform: translateX(33.65884px) translateY(-60px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-66px) scale(.11);
        transform: translateX(35.64829px) translateY(-66px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-72px) scale(.12);
        transform: translateX(37.28156px) translateY(-72px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-78px) scale(.13);
        transform: translateX(38.54233px) translateY(-78px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-84px) scale(.14);
        transform: translateX(39.41799px) translateY(-84px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-90px) scale(.15);
        transform: translateX(39.8998px) translateY(-90px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-96px) scale(.16);
        transform: translateX(39.98294px) translateY(-96px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-102px) scale(.17);
        transform: translateX(39.66659px) translateY(-102px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-108px) scale(.18);
        transform: translateX(38.95391px) translateY(-108px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-114px) scale(.19);
        transform: translateX(37.852px) translateY(-114px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-120px) scale(.2);
        transform: translateX(36.3719px) translateY(-120px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-126px) scale(.21);
        transform: translateX(34.52837px) translateY(-126px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-132px) scale(.22);
        transform: translateX(32.33986px) translateY(-132px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-138px) scale(.23);
        transform: translateX(29.82821px) translateY(-138px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-144px) scale(.24);
        transform: translateX(27.01853px) translateY(-144px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-150px) scale(.25);
        transform: translateX(23.93889px) translateY(-150px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-156px) scale(.26);
        transform: translateX(20.62005px) translateY(-156px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-162px) scale(.27);
        transform: translateX(17.0952px) translateY(-162px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-168px) scale(.28);
        transform: translateX(13.39953px) translateY(-168px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-174px) scale(.29);
        transform: translateX(9.56997px) translateY(-174px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-180px) scale(.3);
        transform: translateX(5.6448px) translateY(-180px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-186px) scale(.31);
        transform: translateX(1.66323px) translateY(-186px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-192px) scale(.32);
        transform: translateX(-2.33497px) translateY(-192px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-198px) scale(.33);
        transform: translateX(-6.30983px) translateY(-198px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-204px) scale(.34);
        transform: translateX(-10.22164px) translateY(-204px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-210px) scale(.35);
        transform: translateX(-14.03133px) translateY(-210px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-216px) scale(.36);
        transform: translateX(-17.70082px) translateY(-216px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-222px) scale(.37);
        transform: translateX(-21.19345px) translateY(-222px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-228px) scale(.38);
        transform: translateX(-24.47432px) translateY(-228px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-234px) scale(.39);
        transform: translateX(-27.51065px) translateY(-234px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-240px) scale(.4);
        transform: translateX(-30.2721px) translateY(-240px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-246px) scale(.41);
        transform: translateX(-32.73108px) translateY(-246px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-252px) scale(.42);
        transform: translateX(-34.86303px) translateY(-252px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-258px) scale(.43);
        transform: translateX(-36.64664px) translateY(-258px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-264px) scale(.44);
        transform: translateX(-38.06408px) translateY(-264px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-270px) scale(.45);
        transform: translateX(-39.1012px) translateY(-270px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-276px) scale(.46);
        transform: translateX(-39.74764px) translateY(-276px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-282px) scale(.47);
        transform: translateX(-39.99693px) translateY(-282px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-288px) scale(.48);
        transform: translateX(-39.84658px) translateY(-288px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-294px) scale(.49);
        transform: translateX(-39.29809px) translateY(-294px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-300px) scale(.5);
        transform: translateX(-38.35695px) translateY(-300px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-306px) scale(.49);
        transform: translateX(-37.03256px) translateY(-306px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-312px) scale(.48);
        transform: translateX(-35.33814px) translateY(-312px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-318px) scale(.47);
        transform: translateX(-33.29063px) translateY(-318px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-324px) scale(.46);
        transform: translateX(-30.91048px) translateY(-324px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-330px) scale(.45);
        transform: translateX(-28.22146px) translateY(-330px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-336px) scale(.44);
        transform: translateX(-25.25043px) translateY(-336px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-342px) scale(.43);
        transform: translateX(-22.02707px) translateY(-342px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-348px) scale(.42);
        transform: translateX(-18.58356px) translateY(-348px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-354px) scale(.41);
        transform: translateX(-14.95428px) translateY(-354px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-360px) scale(.4);
        transform: translateX(-11.17547px) translateY(-360px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-366px) scale(.39);
        transform: translateX(-7.28482px) translateY(-366px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-372px) scale(.38);
        transform: translateX(-3.32114px) translateY(-372px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-378px) scale(.37);
        transform: translateX(.67607px) translateY(-378px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-384px) scale(.36);
        transform: translateX(4.66701px) translateY(-384px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-390px) scale(.35);
        transform: translateX(8.61199px) translateY(-390px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-396px) scale(.34);
        transform: translateX(12.47185px) translateY(-396px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-402px) scale(.33);
        transform: translateX(16.20837px) translateY(-402px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-408px) scale(.32);
        transform: translateX(19.7847px) translateY(-408px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-414px) scale(.31);
        transform: translateX(23.16575px) translateY(-414px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-420px) scale(.3);
        transform: translateX(26.31858px) translateY(-420px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-426px) scale(.29);
        transform: translateX(29.21285px) translateY(-426px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-432px) scale(.28);
        transform: translateX(31.82116px) translateY(-432px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-438px) scale(.27);
        transform: translateX(34.11946px) translateY(-438px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-444px) scale(.26);
        transform: translateX(36.08748px) translateY(-444px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-450px) scale(.25);
        transform: translateX(37.70905px) translateY(-450px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-456px) scale(.24);
        transform: translateX(38.97256px) translateY(-456px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-462px) scale(.23);
        transform: translateX(39.87139px) translateY(-462px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-468px) scale(.22);
        transform: translateX(40.40437px) translateY(-468px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-474px) scale(.21);
        transform: translateX(40.57628px) translateY(-474px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-480px) scale(.2);
        transform: translateX(40.39848px) translateY(-480px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-486px) scale(.19);
        transform: translateX(39.88958px) translateY(-486px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-492px) scale(.18);
        transform: translateX(39.07627px) translateY(-492px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-498px) scale(.17);
        transform: translateX(37.99434px) translateY(-498px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-504px) scale(.16);
        transform: translateX(36.68989px) translateY(-504px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-510px) scale(.15);
        transform: translateX(35.22086px) translateY(-510px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-516px) scale(.14);
        transform: translateX(33.65889px) translateY(-516px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-522px) scale(.13);
        transform: translateX(32.09164px) translateY(-522px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-528px) scale(.12);
        transform: translateX(30.62571px) translateY(-528px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-534px) scale(.11);
        transform: translateX(29.39012px) translateY(-534px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-540px) scale(.1);
        transform: translateX(28.54081px) translateY(-540px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-546px) scale(.09);
        transform: translateX(28.26597px) translateY(-546px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-552px) scale(.08);
        transform: translateX(28.79279px) translateY(-552px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-558px) scale(.07);
        transform: translateX(30.39554px) translateY(-558px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-564px) scale(.06);
        transform: translateX(33.4056px) translateY(-564px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-570px) scale(.05);
        transform: translateX(38.22359px) translateY(-570px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-576px) scale(.04);
        transform: translateX(45.3341px) translateY(-576px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-582px) scale(.03);
        transform: translateX(55.3236px) translateY(-582px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-588px) scale(.02);
        transform: translateX(68.90201px) translateY(-588px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-594px) scale(.01);
        transform: translateX(86.92866px) translateY(-594px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-600px) scale(0);
        transform: translateX(110.44364px) translateY(-600px) scale(0)
    }
  }
  
  @-webkit-keyframes loader_6 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-7px) scale(.01);
        transform: translateX(3.99334px) translateY(-7px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-14px) scale(.02);
        transform: translateX(7.94677px) translateY(-14px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-21px) scale(.03);
        transform: translateX(11.82081px) translateY(-21px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-28px) scale(.04);
        transform: translateX(15.57673px) translateY(-28px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-35px) scale(.05);
        transform: translateX(19.17702px) translateY(-35px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-42px) scale(.06);
        transform: translateX(22.5857px) translateY(-42px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-49px) scale(.07);
        transform: translateX(25.76871px) translateY(-49px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-56px) scale(.08);
        transform: translateX(28.69424px) translateY(-56px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-63px) scale(.09);
        transform: translateX(31.33308px) translateY(-63px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-70px) scale(.1);
        transform: translateX(33.65884px) translateY(-70px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-77px) scale(.11);
        transform: translateX(35.64829px) translateY(-77px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-84px) scale(.12);
        transform: translateX(37.28156px) translateY(-84px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-91px) scale(.13);
        transform: translateX(38.54233px) translateY(-91px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-98px) scale(.14);
        transform: translateX(39.41799px) translateY(-98px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-105px) scale(.15);
        transform: translateX(39.8998px) translateY(-105px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-112px) scale(.16);
        transform: translateX(39.98294px) translateY(-112px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-119px) scale(.17);
        transform: translateX(39.66659px) translateY(-119px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-126px) scale(.18);
        transform: translateX(38.95391px) translateY(-126px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-133px) scale(.19);
        transform: translateX(37.852px) translateY(-133px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-140px) scale(.2);
        transform: translateX(36.3719px) translateY(-140px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-147px) scale(.21);
        transform: translateX(34.52837px) translateY(-147px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-154px) scale(.22);
        transform: translateX(32.33986px) translateY(-154px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-161px) scale(.23);
        transform: translateX(29.82821px) translateY(-161px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-168px) scale(.24);
        transform: translateX(27.01853px) translateY(-168px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-175px) scale(.25);
        transform: translateX(23.93889px) translateY(-175px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-182px) scale(.26);
        transform: translateX(20.62005px) translateY(-182px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-189px) scale(.27);
        transform: translateX(17.0952px) translateY(-189px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-196px) scale(.28);
        transform: translateX(13.39953px) translateY(-196px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-203px) scale(.29);
        transform: translateX(9.56997px) translateY(-203px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-210px) scale(.3);
        transform: translateX(5.6448px) translateY(-210px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-217px) scale(.31);
        transform: translateX(1.66323px) translateY(-217px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-224px) scale(.32);
        transform: translateX(-2.33497px) translateY(-224px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-231px) scale(.33);
        transform: translateX(-6.30983px) translateY(-231px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-238px) scale(.34);
        transform: translateX(-10.22164px) translateY(-238px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-245px) scale(.35);
        transform: translateX(-14.03133px) translateY(-245px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-252px) scale(.36);
        transform: translateX(-17.70082px) translateY(-252px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-259px) scale(.37);
        transform: translateX(-21.19345px) translateY(-259px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-266px) scale(.38);
        transform: translateX(-24.47432px) translateY(-266px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-273px) scale(.39);
        transform: translateX(-27.51065px) translateY(-273px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-280px) scale(.4);
        transform: translateX(-30.2721px) translateY(-280px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-287px) scale(.41);
        transform: translateX(-32.73108px) translateY(-287px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-294px) scale(.42);
        transform: translateX(-34.86303px) translateY(-294px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-301px) scale(.43);
        transform: translateX(-36.64664px) translateY(-301px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-308px) scale(.44);
        transform: translateX(-38.06408px) translateY(-308px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-315px) scale(.45);
        transform: translateX(-39.1012px) translateY(-315px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-322px) scale(.46);
        transform: translateX(-39.74764px) translateY(-322px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-329px) scale(.47);
        transform: translateX(-39.99693px) translateY(-329px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-336px) scale(.48);
        transform: translateX(-39.84658px) translateY(-336px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-343px) scale(.49);
        transform: translateX(-39.29809px) translateY(-343px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-350px) scale(.5);
        transform: translateX(-38.35695px) translateY(-350px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-357px) scale(.49);
        transform: translateX(-37.03256px) translateY(-357px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-364px) scale(.48);
        transform: translateX(-35.33814px) translateY(-364px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-371px) scale(.47);
        transform: translateX(-33.29063px) translateY(-371px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-378px) scale(.46);
        transform: translateX(-30.91048px) translateY(-378px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-385px) scale(.45);
        transform: translateX(-28.22146px) translateY(-385px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-392px) scale(.44);
        transform: translateX(-25.25043px) translateY(-392px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-399px) scale(.43);
        transform: translateX(-22.02707px) translateY(-399px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-406px) scale(.42);
        transform: translateX(-18.58356px) translateY(-406px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-413px) scale(.41);
        transform: translateX(-14.95428px) translateY(-413px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-420px) scale(.4);
        transform: translateX(-11.17547px) translateY(-420px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-427px) scale(.39);
        transform: translateX(-7.28482px) translateY(-427px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-434px) scale(.38);
        transform: translateX(-3.32114px) translateY(-434px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-441px) scale(.37);
        transform: translateX(.67607px) translateY(-441px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-448px) scale(.36);
        transform: translateX(4.66701px) translateY(-448px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-455px) scale(.35);
        transform: translateX(8.61199px) translateY(-455px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-462px) scale(.34);
        transform: translateX(12.47185px) translateY(-462px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-469px) scale(.33);
        transform: translateX(16.20837px) translateY(-469px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-476px) scale(.32);
        transform: translateX(19.7847px) translateY(-476px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-483px) scale(.31);
        transform: translateX(23.16575px) translateY(-483px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-490px) scale(.3);
        transform: translateX(26.31858px) translateY(-490px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-497px) scale(.29);
        transform: translateX(29.21285px) translateY(-497px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-504px) scale(.28);
        transform: translateX(31.82116px) translateY(-504px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-511px) scale(.27);
        transform: translateX(34.11946px) translateY(-511px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-518px) scale(.26);
        transform: translateX(36.08748px) translateY(-518px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-525px) scale(.25);
        transform: translateX(37.70905px) translateY(-525px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-532px) scale(.24);
        transform: translateX(38.97256px) translateY(-532px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-539px) scale(.23);
        transform: translateX(39.87139px) translateY(-539px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-546px) scale(.22);
        transform: translateX(40.40437px) translateY(-546px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-553px) scale(.21);
        transform: translateX(40.57628px) translateY(-553px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-560px) scale(.2);
        transform: translateX(40.39848px) translateY(-560px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-567px) scale(.19);
        transform: translateX(39.88958px) translateY(-567px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-574px) scale(.18);
        transform: translateX(39.07627px) translateY(-574px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-581px) scale(.17);
        transform: translateX(37.99434px) translateY(-581px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-588px) scale(.16);
        transform: translateX(36.68989px) translateY(-588px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-595px) scale(.15);
        transform: translateX(35.22086px) translateY(-595px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-602px) scale(.14);
        transform: translateX(33.65889px) translateY(-602px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-609px) scale(.13);
        transform: translateX(32.09164px) translateY(-609px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-616px) scale(.12);
        transform: translateX(30.62571px) translateY(-616px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-623px) scale(.11);
        transform: translateX(29.39012px) translateY(-623px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-630px) scale(.1);
        transform: translateX(28.54081px) translateY(-630px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-637px) scale(.09);
        transform: translateX(28.26597px) translateY(-637px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-644px) scale(.08);
        transform: translateX(28.79279px) translateY(-644px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-651px) scale(.07);
        transform: translateX(30.39554px) translateY(-651px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-658px) scale(.06);
        transform: translateX(33.4056px) translateY(-658px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-665px) scale(.05);
        transform: translateX(38.22359px) translateY(-665px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-672px) scale(.04);
        transform: translateX(45.3341px) translateY(-672px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-679px) scale(.03);
        transform: translateX(55.3236px) translateY(-679px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-686px) scale(.02);
        transform: translateX(68.90201px) translateY(-686px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-693px) scale(.01);
        transform: translateX(86.92866px) translateY(-693px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-700px) scale(0);
        transform: translateX(110.44364px) translateY(-700px) scale(0)
    }
  }
  
  @keyframes loader_6 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-7px) scale(.01);
        transform: translateX(3.99334px) translateY(-7px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-14px) scale(.02);
        transform: translateX(7.94677px) translateY(-14px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-21px) scale(.03);
        transform: translateX(11.82081px) translateY(-21px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-28px) scale(.04);
        transform: translateX(15.57673px) translateY(-28px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-35px) scale(.05);
        transform: translateX(19.17702px) translateY(-35px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-42px) scale(.06);
        transform: translateX(22.5857px) translateY(-42px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-49px) scale(.07);
        transform: translateX(25.76871px) translateY(-49px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-56px) scale(.08);
        transform: translateX(28.69424px) translateY(-56px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-63px) scale(.09);
        transform: translateX(31.33308px) translateY(-63px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-70px) scale(.1);
        transform: translateX(33.65884px) translateY(-70px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-77px) scale(.11);
        transform: translateX(35.64829px) translateY(-77px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-84px) scale(.12);
        transform: translateX(37.28156px) translateY(-84px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-91px) scale(.13);
        transform: translateX(38.54233px) translateY(-91px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-98px) scale(.14);
        transform: translateX(39.41799px) translateY(-98px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-105px) scale(.15);
        transform: translateX(39.8998px) translateY(-105px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-112px) scale(.16);
        transform: translateX(39.98294px) translateY(-112px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-119px) scale(.17);
        transform: translateX(39.66659px) translateY(-119px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-126px) scale(.18);
        transform: translateX(38.95391px) translateY(-126px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-133px) scale(.19);
        transform: translateX(37.852px) translateY(-133px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-140px) scale(.2);
        transform: translateX(36.3719px) translateY(-140px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-147px) scale(.21);
        transform: translateX(34.52837px) translateY(-147px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-154px) scale(.22);
        transform: translateX(32.33986px) translateY(-154px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-161px) scale(.23);
        transform: translateX(29.82821px) translateY(-161px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-168px) scale(.24);
        transform: translateX(27.01853px) translateY(-168px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-175px) scale(.25);
        transform: translateX(23.93889px) translateY(-175px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-182px) scale(.26);
        transform: translateX(20.62005px) translateY(-182px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-189px) scale(.27);
        transform: translateX(17.0952px) translateY(-189px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-196px) scale(.28);
        transform: translateX(13.39953px) translateY(-196px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-203px) scale(.29);
        transform: translateX(9.56997px) translateY(-203px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-210px) scale(.3);
        transform: translateX(5.6448px) translateY(-210px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-217px) scale(.31);
        transform: translateX(1.66323px) translateY(-217px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-224px) scale(.32);
        transform: translateX(-2.33497px) translateY(-224px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-231px) scale(.33);
        transform: translateX(-6.30983px) translateY(-231px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-238px) scale(.34);
        transform: translateX(-10.22164px) translateY(-238px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-245px) scale(.35);
        transform: translateX(-14.03133px) translateY(-245px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-252px) scale(.36);
        transform: translateX(-17.70082px) translateY(-252px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-259px) scale(.37);
        transform: translateX(-21.19345px) translateY(-259px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-266px) scale(.38);
        transform: translateX(-24.47432px) translateY(-266px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-273px) scale(.39);
        transform: translateX(-27.51065px) translateY(-273px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-280px) scale(.4);
        transform: translateX(-30.2721px) translateY(-280px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-287px) scale(.41);
        transform: translateX(-32.73108px) translateY(-287px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-294px) scale(.42);
        transform: translateX(-34.86303px) translateY(-294px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-301px) scale(.43);
        transform: translateX(-36.64664px) translateY(-301px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-308px) scale(.44);
        transform: translateX(-38.06408px) translateY(-308px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-315px) scale(.45);
        transform: translateX(-39.1012px) translateY(-315px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-322px) scale(.46);
        transform: translateX(-39.74764px) translateY(-322px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-329px) scale(.47);
        transform: translateX(-39.99693px) translateY(-329px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-336px) scale(.48);
        transform: translateX(-39.84658px) translateY(-336px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-343px) scale(.49);
        transform: translateX(-39.29809px) translateY(-343px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-350px) scale(.5);
        transform: translateX(-38.35695px) translateY(-350px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-357px) scale(.49);
        transform: translateX(-37.03256px) translateY(-357px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-364px) scale(.48);
        transform: translateX(-35.33814px) translateY(-364px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-371px) scale(.47);
        transform: translateX(-33.29063px) translateY(-371px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-378px) scale(.46);
        transform: translateX(-30.91048px) translateY(-378px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-385px) scale(.45);
        transform: translateX(-28.22146px) translateY(-385px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-392px) scale(.44);
        transform: translateX(-25.25043px) translateY(-392px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-399px) scale(.43);
        transform: translateX(-22.02707px) translateY(-399px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-406px) scale(.42);
        transform: translateX(-18.58356px) translateY(-406px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-413px) scale(.41);
        transform: translateX(-14.95428px) translateY(-413px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-420px) scale(.4);
        transform: translateX(-11.17547px) translateY(-420px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-427px) scale(.39);
        transform: translateX(-7.28482px) translateY(-427px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-434px) scale(.38);
        transform: translateX(-3.32114px) translateY(-434px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-441px) scale(.37);
        transform: translateX(.67607px) translateY(-441px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-448px) scale(.36);
        transform: translateX(4.66701px) translateY(-448px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-455px) scale(.35);
        transform: translateX(8.61199px) translateY(-455px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-462px) scale(.34);
        transform: translateX(12.47185px) translateY(-462px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-469px) scale(.33);
        transform: translateX(16.20837px) translateY(-469px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-476px) scale(.32);
        transform: translateX(19.7847px) translateY(-476px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-483px) scale(.31);
        transform: translateX(23.16575px) translateY(-483px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-490px) scale(.3);
        transform: translateX(26.31858px) translateY(-490px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-497px) scale(.29);
        transform: translateX(29.21285px) translateY(-497px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-504px) scale(.28);
        transform: translateX(31.82116px) translateY(-504px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-511px) scale(.27);
        transform: translateX(34.11946px) translateY(-511px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-518px) scale(.26);
        transform: translateX(36.08748px) translateY(-518px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-525px) scale(.25);
        transform: translateX(37.70905px) translateY(-525px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-532px) scale(.24);
        transform: translateX(38.97256px) translateY(-532px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-539px) scale(.23);
        transform: translateX(39.87139px) translateY(-539px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-546px) scale(.22);
        transform: translateX(40.40437px) translateY(-546px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-553px) scale(.21);
        transform: translateX(40.57628px) translateY(-553px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-560px) scale(.2);
        transform: translateX(40.39848px) translateY(-560px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-567px) scale(.19);
        transform: translateX(39.88958px) translateY(-567px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-574px) scale(.18);
        transform: translateX(39.07627px) translateY(-574px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-581px) scale(.17);
        transform: translateX(37.99434px) translateY(-581px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-588px) scale(.16);
        transform: translateX(36.68989px) translateY(-588px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-595px) scale(.15);
        transform: translateX(35.22086px) translateY(-595px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-602px) scale(.14);
        transform: translateX(33.65889px) translateY(-602px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-609px) scale(.13);
        transform: translateX(32.09164px) translateY(-609px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-616px) scale(.12);
        transform: translateX(30.62571px) translateY(-616px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-623px) scale(.11);
        transform: translateX(29.39012px) translateY(-623px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-630px) scale(.1);
        transform: translateX(28.54081px) translateY(-630px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-637px) scale(.09);
        transform: translateX(28.26597px) translateY(-637px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-644px) scale(.08);
        transform: translateX(28.79279px) translateY(-644px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-651px) scale(.07);
        transform: translateX(30.39554px) translateY(-651px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-658px) scale(.06);
        transform: translateX(33.4056px) translateY(-658px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-665px) scale(.05);
        transform: translateX(38.22359px) translateY(-665px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-672px) scale(.04);
        transform: translateX(45.3341px) translateY(-672px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-679px) scale(.03);
        transform: translateX(55.3236px) translateY(-679px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-686px) scale(.02);
        transform: translateX(68.90201px) translateY(-686px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-693px) scale(.01);
        transform: translateX(86.92866px) translateY(-693px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-700px) scale(0);
        transform: translateX(110.44364px) translateY(-700px) scale(0)
    }
  }
  
  @-webkit-keyframes loader_7 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-5px) scale(.01);
        transform: translateX(3.99334px) translateY(-5px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-10px) scale(.02);
        transform: translateX(7.94677px) translateY(-10px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-15px) scale(.03);
        transform: translateX(11.82081px) translateY(-15px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-20px) scale(.04);
        transform: translateX(15.57673px) translateY(-20px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-25px) scale(.05);
        transform: translateX(19.17702px) translateY(-25px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-30px) scale(.06);
        transform: translateX(22.5857px) translateY(-30px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-35px) scale(.07);
        transform: translateX(25.76871px) translateY(-35px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-40px) scale(.08);
        transform: translateX(28.69424px) translateY(-40px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-45px) scale(.09);
        transform: translateX(31.33308px) translateY(-45px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-50px) scale(.1);
        transform: translateX(33.65884px) translateY(-50px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-55px) scale(.11);
        transform: translateX(35.64829px) translateY(-55px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-60px) scale(.12);
        transform: translateX(37.28156px) translateY(-60px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-65px) scale(.13);
        transform: translateX(38.54233px) translateY(-65px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-70px) scale(.14);
        transform: translateX(39.41799px) translateY(-70px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-75px) scale(.15);
        transform: translateX(39.8998px) translateY(-75px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-80px) scale(.16);
        transform: translateX(39.98294px) translateY(-80px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-85px) scale(.17);
        transform: translateX(39.66659px) translateY(-85px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-90px) scale(.18);
        transform: translateX(38.95391px) translateY(-90px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-95px) scale(.19);
        transform: translateX(37.852px) translateY(-95px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-100px) scale(.2);
        transform: translateX(36.3719px) translateY(-100px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-105px) scale(.21);
        transform: translateX(34.52837px) translateY(-105px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-110px) scale(.22);
        transform: translateX(32.33986px) translateY(-110px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-115px) scale(.23);
        transform: translateX(29.82821px) translateY(-115px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-120px) scale(.24);
        transform: translateX(27.01853px) translateY(-120px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-125px) scale(.25);
        transform: translateX(23.93889px) translateY(-125px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-130px) scale(.26);
        transform: translateX(20.62005px) translateY(-130px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-135px) scale(.27);
        transform: translateX(17.0952px) translateY(-135px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-140px) scale(.28);
        transform: translateX(13.39953px) translateY(-140px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-145px) scale(.29);
        transform: translateX(9.56997px) translateY(-145px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-150px) scale(.3);
        transform: translateX(5.6448px) translateY(-150px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-155px) scale(.31);
        transform: translateX(1.66323px) translateY(-155px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-160px) scale(.32);
        transform: translateX(-2.33497px) translateY(-160px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-165px) scale(.33);
        transform: translateX(-6.30983px) translateY(-165px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-170px) scale(.34);
        transform: translateX(-10.22164px) translateY(-170px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-175px) scale(.35);
        transform: translateX(-14.03133px) translateY(-175px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-180px) scale(.36);
        transform: translateX(-17.70082px) translateY(-180px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-185px) scale(.37);
        transform: translateX(-21.19345px) translateY(-185px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-190px) scale(.38);
        transform: translateX(-24.47432px) translateY(-190px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-195px) scale(.39);
        transform: translateX(-27.51065px) translateY(-195px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-200px) scale(.4);
        transform: translateX(-30.2721px) translateY(-200px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-205px) scale(.41);
        transform: translateX(-32.73108px) translateY(-205px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-210px) scale(.42);
        transform: translateX(-34.86303px) translateY(-210px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-215px) scale(.43);
        transform: translateX(-36.64664px) translateY(-215px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-220px) scale(.44);
        transform: translateX(-38.06408px) translateY(-220px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-225px) scale(.45);
        transform: translateX(-39.1012px) translateY(-225px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-230px) scale(.46);
        transform: translateX(-39.74764px) translateY(-230px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-235px) scale(.47);
        transform: translateX(-39.99693px) translateY(-235px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-240px) scale(.48);
        transform: translateX(-39.84658px) translateY(-240px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-245px) scale(.49);
        transform: translateX(-39.29809px) translateY(-245px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-250px) scale(.5);
        transform: translateX(-38.35695px) translateY(-250px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-255px) scale(.49);
        transform: translateX(-37.03256px) translateY(-255px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-260px) scale(.48);
        transform: translateX(-35.33814px) translateY(-260px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-265px) scale(.47);
        transform: translateX(-33.29063px) translateY(-265px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-270px) scale(.46);
        transform: translateX(-30.91048px) translateY(-270px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-275px) scale(.45);
        transform: translateX(-28.22146px) translateY(-275px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-280px) scale(.44);
        transform: translateX(-25.25043px) translateY(-280px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-285px) scale(.43);
        transform: translateX(-22.02707px) translateY(-285px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-290px) scale(.42);
        transform: translateX(-18.58356px) translateY(-290px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-295px) scale(.41);
        transform: translateX(-14.95428px) translateY(-295px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-300px) scale(.4);
        transform: translateX(-11.17547px) translateY(-300px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-305px) scale(.39);
        transform: translateX(-7.28482px) translateY(-305px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-310px) scale(.38);
        transform: translateX(-3.32114px) translateY(-310px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-315px) scale(.37);
        transform: translateX(.67607px) translateY(-315px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-320px) scale(.36);
        transform: translateX(4.66701px) translateY(-320px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-325px) scale(.35);
        transform: translateX(8.61199px) translateY(-325px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-330px) scale(.34);
        transform: translateX(12.47185px) translateY(-330px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-335px) scale(.33);
        transform: translateX(16.20837px) translateY(-335px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-340px) scale(.32);
        transform: translateX(19.7847px) translateY(-340px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-345px) scale(.31);
        transform: translateX(23.16575px) translateY(-345px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-350px) scale(.3);
        transform: translateX(26.31858px) translateY(-350px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-355px) scale(.29);
        transform: translateX(29.21285px) translateY(-355px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-360px) scale(.28);
        transform: translateX(31.82116px) translateY(-360px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-365px) scale(.27);
        transform: translateX(34.11946px) translateY(-365px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-370px) scale(.26);
        transform: translateX(36.08748px) translateY(-370px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-375px) scale(.25);
        transform: translateX(37.70905px) translateY(-375px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-380px) scale(.24);
        transform: translateX(38.97256px) translateY(-380px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-385px) scale(.23);
        transform: translateX(39.87139px) translateY(-385px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-390px) scale(.22);
        transform: translateX(40.40437px) translateY(-390px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-395px) scale(.21);
        transform: translateX(40.57628px) translateY(-395px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-400px) scale(.2);
        transform: translateX(40.39848px) translateY(-400px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-405px) scale(.19);
        transform: translateX(39.88958px) translateY(-405px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-410px) scale(.18);
        transform: translateX(39.07627px) translateY(-410px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-415px) scale(.17);
        transform: translateX(37.99434px) translateY(-415px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-420px) scale(.16);
        transform: translateX(36.68989px) translateY(-420px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-425px) scale(.15);
        transform: translateX(35.22086px) translateY(-425px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-430px) scale(.14);
        transform: translateX(33.65889px) translateY(-430px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-435px) scale(.13);
        transform: translateX(32.09164px) translateY(-435px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-440px) scale(.12);
        transform: translateX(30.62571px) translateY(-440px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-445px) scale(.11);
        transform: translateX(29.39012px) translateY(-445px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-450px) scale(.1);
        transform: translateX(28.54081px) translateY(-450px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-455px) scale(.09);
        transform: translateX(28.26597px) translateY(-455px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-460px) scale(.08);
        transform: translateX(28.79279px) translateY(-460px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-465px) scale(.07);
        transform: translateX(30.39554px) translateY(-465px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-470px) scale(.06);
        transform: translateX(33.4056px) translateY(-470px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-475px) scale(.05);
        transform: translateX(38.22359px) translateY(-475px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-480px) scale(.04);
        transform: translateX(45.3341px) translateY(-480px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-485px) scale(.03);
        transform: translateX(55.3236px) translateY(-485px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-490px) scale(.02);
        transform: translateX(68.90201px) translateY(-490px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-495px) scale(.01);
        transform: translateX(86.92866px) translateY(-495px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-500px) scale(0);
        transform: translateX(110.44364px) translateY(-500px) scale(0)
    }
  }
  
  @keyframes loader_7 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-5px) scale(.01);
        transform: translateX(3.99334px) translateY(-5px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-10px) scale(.02);
        transform: translateX(7.94677px) translateY(-10px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-15px) scale(.03);
        transform: translateX(11.82081px) translateY(-15px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-20px) scale(.04);
        transform: translateX(15.57673px) translateY(-20px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-25px) scale(.05);
        transform: translateX(19.17702px) translateY(-25px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-30px) scale(.06);
        transform: translateX(22.5857px) translateY(-30px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-35px) scale(.07);
        transform: translateX(25.76871px) translateY(-35px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-40px) scale(.08);
        transform: translateX(28.69424px) translateY(-40px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-45px) scale(.09);
        transform: translateX(31.33308px) translateY(-45px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-50px) scale(.1);
        transform: translateX(33.65884px) translateY(-50px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-55px) scale(.11);
        transform: translateX(35.64829px) translateY(-55px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-60px) scale(.12);
        transform: translateX(37.28156px) translateY(-60px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-65px) scale(.13);
        transform: translateX(38.54233px) translateY(-65px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-70px) scale(.14);
        transform: translateX(39.41799px) translateY(-70px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-75px) scale(.15);
        transform: translateX(39.8998px) translateY(-75px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-80px) scale(.16);
        transform: translateX(39.98294px) translateY(-80px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-85px) scale(.17);
        transform: translateX(39.66659px) translateY(-85px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-90px) scale(.18);
        transform: translateX(38.95391px) translateY(-90px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-95px) scale(.19);
        transform: translateX(37.852px) translateY(-95px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-100px) scale(.2);
        transform: translateX(36.3719px) translateY(-100px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-105px) scale(.21);
        transform: translateX(34.52837px) translateY(-105px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-110px) scale(.22);
        transform: translateX(32.33986px) translateY(-110px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-115px) scale(.23);
        transform: translateX(29.82821px) translateY(-115px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-120px) scale(.24);
        transform: translateX(27.01853px) translateY(-120px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-125px) scale(.25);
        transform: translateX(23.93889px) translateY(-125px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-130px) scale(.26);
        transform: translateX(20.62005px) translateY(-130px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-135px) scale(.27);
        transform: translateX(17.0952px) translateY(-135px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-140px) scale(.28);
        transform: translateX(13.39953px) translateY(-140px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-145px) scale(.29);
        transform: translateX(9.56997px) translateY(-145px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-150px) scale(.3);
        transform: translateX(5.6448px) translateY(-150px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-155px) scale(.31);
        transform: translateX(1.66323px) translateY(-155px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-160px) scale(.32);
        transform: translateX(-2.33497px) translateY(-160px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-165px) scale(.33);
        transform: translateX(-6.30983px) translateY(-165px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-170px) scale(.34);
        transform: translateX(-10.22164px) translateY(-170px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-175px) scale(.35);
        transform: translateX(-14.03133px) translateY(-175px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-180px) scale(.36);
        transform: translateX(-17.70082px) translateY(-180px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-185px) scale(.37);
        transform: translateX(-21.19345px) translateY(-185px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-190px) scale(.38);
        transform: translateX(-24.47432px) translateY(-190px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-195px) scale(.39);
        transform: translateX(-27.51065px) translateY(-195px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-200px) scale(.4);
        transform: translateX(-30.2721px) translateY(-200px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-205px) scale(.41);
        transform: translateX(-32.73108px) translateY(-205px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-210px) scale(.42);
        transform: translateX(-34.86303px) translateY(-210px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-215px) scale(.43);
        transform: translateX(-36.64664px) translateY(-215px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-220px) scale(.44);
        transform: translateX(-38.06408px) translateY(-220px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-225px) scale(.45);
        transform: translateX(-39.1012px) translateY(-225px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-230px) scale(.46);
        transform: translateX(-39.74764px) translateY(-230px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-235px) scale(.47);
        transform: translateX(-39.99693px) translateY(-235px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-240px) scale(.48);
        transform: translateX(-39.84658px) translateY(-240px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-245px) scale(.49);
        transform: translateX(-39.29809px) translateY(-245px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-250px) scale(.5);
        transform: translateX(-38.35695px) translateY(-250px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-255px) scale(.49);
        transform: translateX(-37.03256px) translateY(-255px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-260px) scale(.48);
        transform: translateX(-35.33814px) translateY(-260px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-265px) scale(.47);
        transform: translateX(-33.29063px) translateY(-265px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-270px) scale(.46);
        transform: translateX(-30.91048px) translateY(-270px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-275px) scale(.45);
        transform: translateX(-28.22146px) translateY(-275px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-280px) scale(.44);
        transform: translateX(-25.25043px) translateY(-280px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-285px) scale(.43);
        transform: translateX(-22.02707px) translateY(-285px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-290px) scale(.42);
        transform: translateX(-18.58356px) translateY(-290px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-295px) scale(.41);
        transform: translateX(-14.95428px) translateY(-295px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-300px) scale(.4);
        transform: translateX(-11.17547px) translateY(-300px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-305px) scale(.39);
        transform: translateX(-7.28482px) translateY(-305px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-310px) scale(.38);
        transform: translateX(-3.32114px) translateY(-310px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-315px) scale(.37);
        transform: translateX(.67607px) translateY(-315px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-320px) scale(.36);
        transform: translateX(4.66701px) translateY(-320px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-325px) scale(.35);
        transform: translateX(8.61199px) translateY(-325px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-330px) scale(.34);
        transform: translateX(12.47185px) translateY(-330px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-335px) scale(.33);
        transform: translateX(16.20837px) translateY(-335px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-340px) scale(.32);
        transform: translateX(19.7847px) translateY(-340px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-345px) scale(.31);
        transform: translateX(23.16575px) translateY(-345px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-350px) scale(.3);
        transform: translateX(26.31858px) translateY(-350px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-355px) scale(.29);
        transform: translateX(29.21285px) translateY(-355px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-360px) scale(.28);
        transform: translateX(31.82116px) translateY(-360px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-365px) scale(.27);
        transform: translateX(34.11946px) translateY(-365px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-370px) scale(.26);
        transform: translateX(36.08748px) translateY(-370px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-375px) scale(.25);
        transform: translateX(37.70905px) translateY(-375px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-380px) scale(.24);
        transform: translateX(38.97256px) translateY(-380px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-385px) scale(.23);
        transform: translateX(39.87139px) translateY(-385px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-390px) scale(.22);
        transform: translateX(40.40437px) translateY(-390px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-395px) scale(.21);
        transform: translateX(40.57628px) translateY(-395px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-400px) scale(.2);
        transform: translateX(40.39848px) translateY(-400px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-405px) scale(.19);
        transform: translateX(39.88958px) translateY(-405px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-410px) scale(.18);
        transform: translateX(39.07627px) translateY(-410px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-415px) scale(.17);
        transform: translateX(37.99434px) translateY(-415px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-420px) scale(.16);
        transform: translateX(36.68989px) translateY(-420px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-425px) scale(.15);
        transform: translateX(35.22086px) translateY(-425px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-430px) scale(.14);
        transform: translateX(33.65889px) translateY(-430px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-435px) scale(.13);
        transform: translateX(32.09164px) translateY(-435px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-440px) scale(.12);
        transform: translateX(30.62571px) translateY(-440px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-445px) scale(.11);
        transform: translateX(29.39012px) translateY(-445px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-450px) scale(.1);
        transform: translateX(28.54081px) translateY(-450px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-455px) scale(.09);
        transform: translateX(28.26597px) translateY(-455px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-460px) scale(.08);
        transform: translateX(28.79279px) translateY(-460px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-465px) scale(.07);
        transform: translateX(30.39554px) translateY(-465px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-470px) scale(.06);
        transform: translateX(33.4056px) translateY(-470px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-475px) scale(.05);
        transform: translateX(38.22359px) translateY(-475px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-480px) scale(.04);
        transform: translateX(45.3341px) translateY(-480px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-485px) scale(.03);
        transform: translateX(55.3236px) translateY(-485px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-490px) scale(.02);
        transform: translateX(68.90201px) translateY(-490px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-495px) scale(.01);
        transform: translateX(86.92866px) translateY(-495px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-500px) scale(0);
        transform: translateX(110.44364px) translateY(-500px) scale(0)
    }
  }
  
  @-webkit-keyframes loader_8 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-7px) scale(.01);
        transform: translateX(3.99334px) translateY(-7px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-14px) scale(.02);
        transform: translateX(7.94677px) translateY(-14px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-21px) scale(.03);
        transform: translateX(11.82081px) translateY(-21px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-28px) scale(.04);
        transform: translateX(15.57673px) translateY(-28px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-35px) scale(.05);
        transform: translateX(19.17702px) translateY(-35px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-42px) scale(.06);
        transform: translateX(22.5857px) translateY(-42px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-49px) scale(.07);
        transform: translateX(25.76871px) translateY(-49px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-56px) scale(.08);
        transform: translateX(28.69424px) translateY(-56px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-63px) scale(.09);
        transform: translateX(31.33308px) translateY(-63px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-70px) scale(.1);
        transform: translateX(33.65884px) translateY(-70px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-77px) scale(.11);
        transform: translateX(35.64829px) translateY(-77px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-84px) scale(.12);
        transform: translateX(37.28156px) translateY(-84px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-91px) scale(.13);
        transform: translateX(38.54233px) translateY(-91px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-98px) scale(.14);
        transform: translateX(39.41799px) translateY(-98px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-105px) scale(.15);
        transform: translateX(39.8998px) translateY(-105px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-112px) scale(.16);
        transform: translateX(39.98294px) translateY(-112px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-119px) scale(.17);
        transform: translateX(39.66659px) translateY(-119px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-126px) scale(.18);
        transform: translateX(38.95391px) translateY(-126px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-133px) scale(.19);
        transform: translateX(37.852px) translateY(-133px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-140px) scale(.2);
        transform: translateX(36.3719px) translateY(-140px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-147px) scale(.21);
        transform: translateX(34.52837px) translateY(-147px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-154px) scale(.22);
        transform: translateX(32.33986px) translateY(-154px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-161px) scale(.23);
        transform: translateX(29.82821px) translateY(-161px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-168px) scale(.24);
        transform: translateX(27.01853px) translateY(-168px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-175px) scale(.25);
        transform: translateX(23.93889px) translateY(-175px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-182px) scale(.26);
        transform: translateX(20.62005px) translateY(-182px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-189px) scale(.27);
        transform: translateX(17.0952px) translateY(-189px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-196px) scale(.28);
        transform: translateX(13.39953px) translateY(-196px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-203px) scale(.29);
        transform: translateX(9.56997px) translateY(-203px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-210px) scale(.3);
        transform: translateX(5.6448px) translateY(-210px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-217px) scale(.31);
        transform: translateX(1.66323px) translateY(-217px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-224px) scale(.32);
        transform: translateX(-2.33497px) translateY(-224px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-231px) scale(.33);
        transform: translateX(-6.30983px) translateY(-231px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-238px) scale(.34);
        transform: translateX(-10.22164px) translateY(-238px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-245px) scale(.35);
        transform: translateX(-14.03133px) translateY(-245px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-252px) scale(.36);
        transform: translateX(-17.70082px) translateY(-252px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-259px) scale(.37);
        transform: translateX(-21.19345px) translateY(-259px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-266px) scale(.38);
        transform: translateX(-24.47432px) translateY(-266px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-273px) scale(.39);
        transform: translateX(-27.51065px) translateY(-273px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-280px) scale(.4);
        transform: translateX(-30.2721px) translateY(-280px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-287px) scale(.41);
        transform: translateX(-32.73108px) translateY(-287px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-294px) scale(.42);
        transform: translateX(-34.86303px) translateY(-294px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-301px) scale(.43);
        transform: translateX(-36.64664px) translateY(-301px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-308px) scale(.44);
        transform: translateX(-38.06408px) translateY(-308px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-315px) scale(.45);
        transform: translateX(-39.1012px) translateY(-315px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-322px) scale(.46);
        transform: translateX(-39.74764px) translateY(-322px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-329px) scale(.47);
        transform: translateX(-39.99693px) translateY(-329px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-336px) scale(.48);
        transform: translateX(-39.84658px) translateY(-336px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-343px) scale(.49);
        transform: translateX(-39.29809px) translateY(-343px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-350px) scale(.5);
        transform: translateX(-38.35695px) translateY(-350px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-357px) scale(.49);
        transform: translateX(-37.03256px) translateY(-357px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-364px) scale(.48);
        transform: translateX(-35.33814px) translateY(-364px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-371px) scale(.47);
        transform: translateX(-33.29063px) translateY(-371px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-378px) scale(.46);
        transform: translateX(-30.91048px) translateY(-378px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-385px) scale(.45);
        transform: translateX(-28.22146px) translateY(-385px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-392px) scale(.44);
        transform: translateX(-25.25043px) translateY(-392px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-399px) scale(.43);
        transform: translateX(-22.02707px) translateY(-399px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-406px) scale(.42);
        transform: translateX(-18.58356px) translateY(-406px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-413px) scale(.41);
        transform: translateX(-14.95428px) translateY(-413px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-420px) scale(.4);
        transform: translateX(-11.17547px) translateY(-420px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-427px) scale(.39);
        transform: translateX(-7.28482px) translateY(-427px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-434px) scale(.38);
        transform: translateX(-3.32114px) translateY(-434px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-441px) scale(.37);
        transform: translateX(.67607px) translateY(-441px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-448px) scale(.36);
        transform: translateX(4.66701px) translateY(-448px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-455px) scale(.35);
        transform: translateX(8.61199px) translateY(-455px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-462px) scale(.34);
        transform: translateX(12.47185px) translateY(-462px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-469px) scale(.33);
        transform: translateX(16.20837px) translateY(-469px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-476px) scale(.32);
        transform: translateX(19.7847px) translateY(-476px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-483px) scale(.31);
        transform: translateX(23.16575px) translateY(-483px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-490px) scale(.3);
        transform: translateX(26.31858px) translateY(-490px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-497px) scale(.29);
        transform: translateX(29.21285px) translateY(-497px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-504px) scale(.28);
        transform: translateX(31.82116px) translateY(-504px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-511px) scale(.27);
        transform: translateX(34.11946px) translateY(-511px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-518px) scale(.26);
        transform: translateX(36.08748px) translateY(-518px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-525px) scale(.25);
        transform: translateX(37.70905px) translateY(-525px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-532px) scale(.24);
        transform: translateX(38.97256px) translateY(-532px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-539px) scale(.23);
        transform: translateX(39.87139px) translateY(-539px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-546px) scale(.22);
        transform: translateX(40.40437px) translateY(-546px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-553px) scale(.21);
        transform: translateX(40.57628px) translateY(-553px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-560px) scale(.2);
        transform: translateX(40.39848px) translateY(-560px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-567px) scale(.19);
        transform: translateX(39.88958px) translateY(-567px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-574px) scale(.18);
        transform: translateX(39.07627px) translateY(-574px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-581px) scale(.17);
        transform: translateX(37.99434px) translateY(-581px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-588px) scale(.16);
        transform: translateX(36.68989px) translateY(-588px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-595px) scale(.15);
        transform: translateX(35.22086px) translateY(-595px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-602px) scale(.14);
        transform: translateX(33.65889px) translateY(-602px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-609px) scale(.13);
        transform: translateX(32.09164px) translateY(-609px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-616px) scale(.12);
        transform: translateX(30.62571px) translateY(-616px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-623px) scale(.11);
        transform: translateX(29.39012px) translateY(-623px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-630px) scale(.1);
        transform: translateX(28.54081px) translateY(-630px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-637px) scale(.09);
        transform: translateX(28.26597px) translateY(-637px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-644px) scale(.08);
        transform: translateX(28.79279px) translateY(-644px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-651px) scale(.07);
        transform: translateX(30.39554px) translateY(-651px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-658px) scale(.06);
        transform: translateX(33.4056px) translateY(-658px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-665px) scale(.05);
        transform: translateX(38.22359px) translateY(-665px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-672px) scale(.04);
        transform: translateX(45.3341px) translateY(-672px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-679px) scale(.03);
        transform: translateX(55.3236px) translateY(-679px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-686px) scale(.02);
        transform: translateX(68.90201px) translateY(-686px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-693px) scale(.01);
        transform: translateX(86.92866px) translateY(-693px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-700px) scale(0);
        transform: translateX(110.44364px) translateY(-700px) scale(0)
    }
  }
  
  @keyframes loader_8 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) scale(0);
        transform: translateX(0px) translateY(0px) scale(0)
    }
  
    1% {
        -webkit-transform: translateX(3.99334px) translateY(-7px) scale(.01);
        transform: translateX(3.99334px) translateY(-7px) scale(.01)
    }
  
    2% {
        -webkit-transform: translateX(7.94677px) translateY(-14px) scale(.02);
        transform: translateX(7.94677px) translateY(-14px) scale(.02)
    }
  
    3% {
        -webkit-transform: translateX(11.82081px) translateY(-21px) scale(.03);
        transform: translateX(11.82081px) translateY(-21px) scale(.03)
    }
  
    4% {
        -webkit-transform: translateX(15.57673px) translateY(-28px) scale(.04);
        transform: translateX(15.57673px) translateY(-28px) scale(.04)
    }
  
    5% {
        -webkit-transform: translateX(19.17702px) translateY(-35px) scale(.05);
        transform: translateX(19.17702px) translateY(-35px) scale(.05)
    }
  
    6% {
        -webkit-transform: translateX(22.5857px) translateY(-42px) scale(.06);
        transform: translateX(22.5857px) translateY(-42px) scale(.06)
    }
  
    7% {
        -webkit-transform: translateX(25.76871px) translateY(-49px) scale(.07);
        transform: translateX(25.76871px) translateY(-49px) scale(.07)
    }
  
    8% {
        -webkit-transform: translateX(28.69424px) translateY(-56px) scale(.08);
        transform: translateX(28.69424px) translateY(-56px) scale(.08)
    }
  
    9% {
        -webkit-transform: translateX(31.33308px) translateY(-63px) scale(.09);
        transform: translateX(31.33308px) translateY(-63px) scale(.09)
    }
  
    10% {
        -webkit-transform: translateX(33.65884px) translateY(-70px) scale(.1);
        transform: translateX(33.65884px) translateY(-70px) scale(.1)
    }
  
    11% {
        -webkit-transform: translateX(35.64829px) translateY(-77px) scale(.11);
        transform: translateX(35.64829px) translateY(-77px) scale(.11)
    }
  
    12% {
        -webkit-transform: translateX(37.28156px) translateY(-84px) scale(.12);
        transform: translateX(37.28156px) translateY(-84px) scale(.12)
    }
  
    13% {
        -webkit-transform: translateX(38.54233px) translateY(-91px) scale(.13);
        transform: translateX(38.54233px) translateY(-91px) scale(.13)
    }
  
    14% {
        -webkit-transform: translateX(39.41799px) translateY(-98px) scale(.14);
        transform: translateX(39.41799px) translateY(-98px) scale(.14)
    }
  
    15% {
        -webkit-transform: translateX(39.8998px) translateY(-105px) scale(.15);
        transform: translateX(39.8998px) translateY(-105px) scale(.15)
    }
  
    16% {
        -webkit-transform: translateX(39.98294px) translateY(-112px) scale(.16);
        transform: translateX(39.98294px) translateY(-112px) scale(.16)
    }
  
    17% {
        -webkit-transform: translateX(39.66659px) translateY(-119px) scale(.17);
        transform: translateX(39.66659px) translateY(-119px) scale(.17)
    }
  
    18% {
        -webkit-transform: translateX(38.95391px) translateY(-126px) scale(.18);
        transform: translateX(38.95391px) translateY(-126px) scale(.18)
    }
  
    19% {
        -webkit-transform: translateX(37.852px) translateY(-133px) scale(.19);
        transform: translateX(37.852px) translateY(-133px) scale(.19)
    }
  
    20% {
        -webkit-transform: translateX(36.3719px) translateY(-140px) scale(.2);
        transform: translateX(36.3719px) translateY(-140px) scale(.2)
    }
  
    21% {
        -webkit-transform: translateX(34.52837px) translateY(-147px) scale(.21);
        transform: translateX(34.52837px) translateY(-147px) scale(.21)
    }
  
    22% {
        -webkit-transform: translateX(32.33986px) translateY(-154px) scale(.22);
        transform: translateX(32.33986px) translateY(-154px) scale(.22)
    }
  
    23% {
        -webkit-transform: translateX(29.82821px) translateY(-161px) scale(.23);
        transform: translateX(29.82821px) translateY(-161px) scale(.23)
    }
  
    24% {
        -webkit-transform: translateX(27.01853px) translateY(-168px) scale(.24);
        transform: translateX(27.01853px) translateY(-168px) scale(.24)
    }
  
    25% {
        -webkit-transform: translateX(23.93889px) translateY(-175px) scale(.25);
        transform: translateX(23.93889px) translateY(-175px) scale(.25)
    }
  
    26% {
        -webkit-transform: translateX(20.62005px) translateY(-182px) scale(.26);
        transform: translateX(20.62005px) translateY(-182px) scale(.26)
    }
  
    27% {
        -webkit-transform: translateX(17.0952px) translateY(-189px) scale(.27);
        transform: translateX(17.0952px) translateY(-189px) scale(.27)
    }
  
    28% {
        -webkit-transform: translateX(13.39953px) translateY(-196px) scale(.28);
        transform: translateX(13.39953px) translateY(-196px) scale(.28)
    }
  
    29% {
        -webkit-transform: translateX(9.56997px) translateY(-203px) scale(.29);
        transform: translateX(9.56997px) translateY(-203px) scale(.29)
    }
  
    30% {
        -webkit-transform: translateX(5.6448px) translateY(-210px) scale(.3);
        transform: translateX(5.6448px) translateY(-210px) scale(.3)
    }
  
    31% {
        -webkit-transform: translateX(1.66323px) translateY(-217px) scale(.31);
        transform: translateX(1.66323px) translateY(-217px) scale(.31)
    }
  
    32% {
        -webkit-transform: translateX(-2.33497px) translateY(-224px) scale(.32);
        transform: translateX(-2.33497px) translateY(-224px) scale(.32)
    }
  
    33% {
        -webkit-transform: translateX(-6.30983px) translateY(-231px) scale(.33);
        transform: translateX(-6.30983px) translateY(-231px) scale(.33)
    }
  
    34% {
        -webkit-transform: translateX(-10.22164px) translateY(-238px) scale(.34);
        transform: translateX(-10.22164px) translateY(-238px) scale(.34)
    }
  
    35% {
        -webkit-transform: translateX(-14.03133px) translateY(-245px) scale(.35);
        transform: translateX(-14.03133px) translateY(-245px) scale(.35)
    }
  
    36% {
        -webkit-transform: translateX(-17.70082px) translateY(-252px) scale(.36);
        transform: translateX(-17.70082px) translateY(-252px) scale(.36)
    }
  
    37% {
        -webkit-transform: translateX(-21.19345px) translateY(-259px) scale(.37);
        transform: translateX(-21.19345px) translateY(-259px) scale(.37)
    }
  
    38% {
        -webkit-transform: translateX(-24.47432px) translateY(-266px) scale(.38);
        transform: translateX(-24.47432px) translateY(-266px) scale(.38)
    }
  
    39% {
        -webkit-transform: translateX(-27.51065px) translateY(-273px) scale(.39);
        transform: translateX(-27.51065px) translateY(-273px) scale(.39)
    }
  
    40% {
        -webkit-transform: translateX(-30.2721px) translateY(-280px) scale(.4);
        transform: translateX(-30.2721px) translateY(-280px) scale(.4)
    }
  
    41% {
        -webkit-transform: translateX(-32.73108px) translateY(-287px) scale(.41);
        transform: translateX(-32.73108px) translateY(-287px) scale(.41)
    }
  
    42% {
        -webkit-transform: translateX(-34.86303px) translateY(-294px) scale(.42);
        transform: translateX(-34.86303px) translateY(-294px) scale(.42)
    }
  
    43% {
        -webkit-transform: translateX(-36.64664px) translateY(-301px) scale(.43);
        transform: translateX(-36.64664px) translateY(-301px) scale(.43)
    }
  
    44% {
        -webkit-transform: translateX(-38.06408px) translateY(-308px) scale(.44);
        transform: translateX(-38.06408px) translateY(-308px) scale(.44)
    }
  
    45% {
        -webkit-transform: translateX(-39.1012px) translateY(-315px) scale(.45);
        transform: translateX(-39.1012px) translateY(-315px) scale(.45)
    }
  
    46% {
        -webkit-transform: translateX(-39.74764px) translateY(-322px) scale(.46);
        transform: translateX(-39.74764px) translateY(-322px) scale(.46)
    }
  
    47% {
        -webkit-transform: translateX(-39.99693px) translateY(-329px) scale(.47);
        transform: translateX(-39.99693px) translateY(-329px) scale(.47)
    }
  
    48% {
        -webkit-transform: translateX(-39.84658px) translateY(-336px) scale(.48);
        transform: translateX(-39.84658px) translateY(-336px) scale(.48)
    }
  
    49% {
        -webkit-transform: translateX(-39.29809px) translateY(-343px) scale(.49);
        transform: translateX(-39.29809px) translateY(-343px) scale(.49)
    }
  
    50% {
        -webkit-transform: translateX(-38.35695px) translateY(-350px) scale(.5);
        transform: translateX(-38.35695px) translateY(-350px) scale(.5)
    }
  
    51% {
        -webkit-transform: translateX(-37.03256px) translateY(-357px) scale(.49);
        transform: translateX(-37.03256px) translateY(-357px) scale(.49)
    }
  
    52% {
        -webkit-transform: translateX(-35.33814px) translateY(-364px) scale(.48);
        transform: translateX(-35.33814px) translateY(-364px) scale(.48)
    }
  
    53% {
        -webkit-transform: translateX(-33.29063px) translateY(-371px) scale(.47);
        transform: translateX(-33.29063px) translateY(-371px) scale(.47)
    }
  
    54% {
        -webkit-transform: translateX(-30.91048px) translateY(-378px) scale(.46);
        transform: translateX(-30.91048px) translateY(-378px) scale(.46)
    }
  
    55% {
        -webkit-transform: translateX(-28.22146px) translateY(-385px) scale(.45);
        transform: translateX(-28.22146px) translateY(-385px) scale(.45)
    }
  
    56% {
        -webkit-transform: translateX(-25.25043px) translateY(-392px) scale(.44);
        transform: translateX(-25.25043px) translateY(-392px) scale(.44)
    }
  
    57% {
        -webkit-transform: translateX(-22.02707px) translateY(-399px) scale(.43);
        transform: translateX(-22.02707px) translateY(-399px) scale(.43)
    }
  
    58% {
        -webkit-transform: translateX(-18.58356px) translateY(-406px) scale(.42);
        transform: translateX(-18.58356px) translateY(-406px) scale(.42)
    }
  
    59% {
        -webkit-transform: translateX(-14.95428px) translateY(-413px) scale(.41);
        transform: translateX(-14.95428px) translateY(-413px) scale(.41)
    }
  
    60% {
        -webkit-transform: translateX(-11.17547px) translateY(-420px) scale(.4);
        transform: translateX(-11.17547px) translateY(-420px) scale(.4)
    }
  
    61% {
        -webkit-transform: translateX(-7.28482px) translateY(-427px) scale(.39);
        transform: translateX(-7.28482px) translateY(-427px) scale(.39)
    }
  
    62% {
        -webkit-transform: translateX(-3.32114px) translateY(-434px) scale(.38);
        transform: translateX(-3.32114px) translateY(-434px) scale(.38)
    }
  
    63% {
        -webkit-transform: translateX(.67607px) translateY(-441px) scale(.37);
        transform: translateX(.67607px) translateY(-441px) scale(.37)
    }
  
    64% {
        -webkit-transform: translateX(4.66701px) translateY(-448px) scale(.36);
        transform: translateX(4.66701px) translateY(-448px) scale(.36)
    }
  
    65% {
        -webkit-transform: translateX(8.61199px) translateY(-455px) scale(.35);
        transform: translateX(8.61199px) translateY(-455px) scale(.35)
    }
  
    66% {
        -webkit-transform: translateX(12.47185px) translateY(-462px) scale(.34);
        transform: translateX(12.47185px) translateY(-462px) scale(.34)
    }
  
    67% {
        -webkit-transform: translateX(16.20837px) translateY(-469px) scale(.33);
        transform: translateX(16.20837px) translateY(-469px) scale(.33)
    }
  
    68% {
        -webkit-transform: translateX(19.7847px) translateY(-476px) scale(.32);
        transform: translateX(19.7847px) translateY(-476px) scale(.32)
    }
  
    69% {
        -webkit-transform: translateX(23.16575px) translateY(-483px) scale(.31);
        transform: translateX(23.16575px) translateY(-483px) scale(.31)
    }
  
    70% {
        -webkit-transform: translateX(26.31858px) translateY(-490px) scale(.3);
        transform: translateX(26.31858px) translateY(-490px) scale(.3)
    }
  
    71% {
        -webkit-transform: translateX(29.21285px) translateY(-497px) scale(.29);
        transform: translateX(29.21285px) translateY(-497px) scale(.29)
    }
  
    72% {
        -webkit-transform: translateX(31.82116px) translateY(-504px) scale(.28);
        transform: translateX(31.82116px) translateY(-504px) scale(.28)
    }
  
    73% {
        -webkit-transform: translateX(34.11946px) translateY(-511px) scale(.27);
        transform: translateX(34.11946px) translateY(-511px) scale(.27)
    }
  
    74% {
        -webkit-transform: translateX(36.08748px) translateY(-518px) scale(.26);
        transform: translateX(36.08748px) translateY(-518px) scale(.26)
    }
  
    75% {
        -webkit-transform: translateX(37.70905px) translateY(-525px) scale(.25);
        transform: translateX(37.70905px) translateY(-525px) scale(.25)
    }
  
    76% {
        -webkit-transform: translateX(38.97256px) translateY(-532px) scale(.24);
        transform: translateX(38.97256px) translateY(-532px) scale(.24)
    }
  
    77% {
        -webkit-transform: translateX(39.87139px) translateY(-539px) scale(.23);
        transform: translateX(39.87139px) translateY(-539px) scale(.23)
    }
  
    78% {
        -webkit-transform: translateX(40.40437px) translateY(-546px) scale(.22);
        transform: translateX(40.40437px) translateY(-546px) scale(.22)
    }
  
    79% {
        -webkit-transform: translateX(40.57628px) translateY(-553px) scale(.21);
        transform: translateX(40.57628px) translateY(-553px) scale(.21)
    }
  
    80% {
        -webkit-transform: translateX(40.39848px) translateY(-560px) scale(.2);
        transform: translateX(40.39848px) translateY(-560px) scale(.2)
    }
  
    81% {
        -webkit-transform: translateX(39.88958px) translateY(-567px) scale(.19);
        transform: translateX(39.88958px) translateY(-567px) scale(.19)
    }
  
    82% {
        -webkit-transform: translateX(39.07627px) translateY(-574px) scale(.18);
        transform: translateX(39.07627px) translateY(-574px) scale(.18)
    }
  
    83% {
        -webkit-transform: translateX(37.99434px) translateY(-581px) scale(.17);
        transform: translateX(37.99434px) translateY(-581px) scale(.17)
    }
  
    84% {
        -webkit-transform: translateX(36.68989px) translateY(-588px) scale(.16);
        transform: translateX(36.68989px) translateY(-588px) scale(.16)
    }
  
    85% {
        -webkit-transform: translateX(35.22086px) translateY(-595px) scale(.15);
        transform: translateX(35.22086px) translateY(-595px) scale(.15)
    }
  
    86% {
        -webkit-transform: translateX(33.65889px) translateY(-602px) scale(.14);
        transform: translateX(33.65889px) translateY(-602px) scale(.14)
    }
  
    87% {
        -webkit-transform: translateX(32.09164px) translateY(-609px) scale(.13);
        transform: translateX(32.09164px) translateY(-609px) scale(.13)
    }
  
    88% {
        -webkit-transform: translateX(30.62571px) translateY(-616px) scale(.12);
        transform: translateX(30.62571px) translateY(-616px) scale(.12)
    }
  
    89% {
        -webkit-transform: translateX(29.39012px) translateY(-623px) scale(.11);
        transform: translateX(29.39012px) translateY(-623px) scale(.11)
    }
  
    90% {
        -webkit-transform: translateX(28.54081px) translateY(-630px) scale(.1);
        transform: translateX(28.54081px) translateY(-630px) scale(.1)
    }
  
    91% {
        -webkit-transform: translateX(28.26597px) translateY(-637px) scale(.09);
        transform: translateX(28.26597px) translateY(-637px) scale(.09)
    }
  
    92% {
        -webkit-transform: translateX(28.79279px) translateY(-644px) scale(.08);
        transform: translateX(28.79279px) translateY(-644px) scale(.08)
    }
  
    93% {
        -webkit-transform: translateX(30.39554px) translateY(-651px) scale(.07);
        transform: translateX(30.39554px) translateY(-651px) scale(.07)
    }
  
    94% {
        -webkit-transform: translateX(33.4056px) translateY(-658px) scale(.06);
        transform: translateX(33.4056px) translateY(-658px) scale(.06)
    }
  
    95% {
        -webkit-transform: translateX(38.22359px) translateY(-665px) scale(.05);
        transform: translateX(38.22359px) translateY(-665px) scale(.05)
    }
  
    96% {
        -webkit-transform: translateX(45.3341px) translateY(-672px) scale(.04);
        transform: translateX(45.3341px) translateY(-672px) scale(.04)
    }
  
    97% {
        -webkit-transform: translateX(55.3236px) translateY(-679px) scale(.03);
        transform: translateX(55.3236px) translateY(-679px) scale(.03)
    }
  
    98% {
        -webkit-transform: translateX(68.90201px) translateY(-686px) scale(.02);
        transform: translateX(68.90201px) translateY(-686px) scale(.02)
    }
  
    99% {
        -webkit-transform: translateX(86.92866px) translateY(-693px) scale(.01);
        transform: translateX(86.92866px) translateY(-693px) scale(.01)
    }
  
    100% {
        -webkit-transform: translateX(110.44364px) translateY(-700px) scale(0);
        transform: translateX(110.44364px) translateY(-700px) scale(0)
    }
  }
